import { render, staticRenderFns } from "./termInput.vue?vue&type=template&id=548bcdea&scoped=true&"
import script from "./termInput.vue?vue&type=script&lang=js&"
export * from "./termInput.vue?vue&type=script&lang=js&"
import style0 from "./termInput.vue?vue&type=style&index=0&id=548bcdea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "548bcdea",
  null
  
)

export default component.exports