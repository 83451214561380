<template>
 
   <div class="dialog-body mt-20">
     <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
           <el-form-item label="控制密码" prop="pwd">
               <el-input v-model="ruleForm.pwd" type="password"></el-input>
           </el-form-item>
         
     </el-form>
   </div>    

</template>

<script>
   export default {
       data(){
           return {
               ruleForm:{
                   pwd: ''
               },
               rules:{
                   pwd: [
                       { required: true, message: '请输入控制密码', trigger: 'blur' },
                   ],
               }
           }
       },
       submit (dialog, callback) {
          this.$refs.ruleForm.validate( async(valid) => {
            if (valid) {
              callback(this.ruleForm.pwd);
              dialog.okLoading = false;
              dialog.close()
            } 
          });
      
      } 
    
   }
</script>

<style lang="scss" scoped>
/*垂直居中 */
.custom-dialog-wrapper {
 display: flex;
 align-items: center;
 justify-content: center;
 
 
}

</style>