<template>
  <el-radio-group v-model="value" @change="toChange">
    <el-radio v-for="(item,i) of radioList" :key="'action'+i" :label="item.value">{{ item.name }}</el-radio>
  </el-radio-group>

</template>

<script>
  export default {
    props:{
      list:{
         type: Array,
         default: () => []
      },
      formInfos:{
        type: Object,
        default: () => ({})
      }
    },
    watch:{
      'formInfos':{
        handler (val){
        
            if(val) {
              this.value= `${val.controlAction}-${val.openPercent}`           
            }else{
              this.value= '1-100'
            }
            
            this.toChange()
         },
         immediate: true,
         deep: true
      },
    },
    data(){
      return {
        value: '',

      }
    },
    computed:{
      radioList(){
        let data = this.list.map(item => {
            item.value = `${item.funValue}-${item.openPercent}`
            return item
         })
         return data
      },
      getInfos(){
        let result = null
        this.radioList.map(item => {
           if(item.value == this.value) result = item
        })
        return result  
      },
    },
    methods:{
     
      toChange(){

        this.initChange = true
        let data = this.value.split('-')
        
        this.$emit('change',{
          value: this.value,
          controlAction: parseInt(data[0]),
          openPercent: parseInt(data[1]),
          list: this.radioList,
          info: this.getInfos
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
::v-deep .el-radio__label {
  width: 100% !important;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 18px;
  // word-wrap: break-word !important;    无效
}
.el-radio {
   margin-right: 14px;
}
</style>