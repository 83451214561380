<template>  
  <div class="wh100 d-flex justify-content-center align-items-center">
    <template v-for="(item,i) of list">
      <itemInfo :key="item.id" :taskId="taskId" :infos="item" :index="i" :class="i > 0 ?'ml-70':''"></itemInfo>
    </template>
    <!-- <fromItem :taskId="taskId" ></fromItem> -->
    <addInfo v-if="taskLength == 1" @click="toAdd" class="ml-70"></addInfo>
    
    
    <hnEmptyData setType="col" size="160px" text="暂无定时设置" v-if="!taskLength">
      <!-- <fromItem :taskId="taskId"></fromItem> -->
      <el-button size="mini" @click="toAdd" class="update_primary_but" type="primary" plain>立即新增</el-button>
    </hnEmptyData> 
  </div>
</template>

<script>
  import addInfo from './addInfo.vue'
  import itemInfo from './itemInfo.vue'
  import fromItem from './fromItem.vue'
  export default {
     components:{ addInfo,itemInfo,fromItem },
     props:{
       list:{
          type: Array,
          default:() => []
       },
       taskId: String
     },
     computed:{
        taskLength(){ return this.list.length }
          
     },
     methods:{
      toAdd(){
         this.showDialog(fromItem,{
          props:{
            taskId: this.taskId
          }
         },{
          width:'500px',
          title: '新增定时控制',
        },()=> {
            
        })
       },
     }
  
  }
</script>

<style lang="scss" scoped>
.ml-70{
  margin-left: 70px;
}
</style>