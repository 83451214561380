<template>
  <div class="linkageFrom">
      <el-form :model="ctlForm" :rules="rules" ref="ctlForm" label-width="110px" size="small" class="demo-ruleForm">

          <el-form-item label="有效期" prop="time">
            <div class="termTime_box">
                  <el-date-picker
                  v-model="ctlForm.time"
                  type="daterange"
                  align="right"
                  value-format="yyyy-MM-dd"
                  format="yyyy 年 MM 月 dd 日"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  style="width: 100%"
                  :clearable="false"
                  @change="timeChange('dayBut')"
                  >
                  </el-date-picker>
                  <el-button :type="butStutas.dayBut" @click="allDay" size="mini" class="allDay_but" plain icon="el-icon-check">长期执行</el-button>
            </div>
          </el-form-item>

          <el-form-item label="执行时间段" prop='timeSlot'>
              <!-- <week v-model="ctlForm.week"></week> -->
              <div class="termTime_box">
                 <el-time-picker
                      v-model="ctlForm.timeSlot"
                      value-format="HH:mm"
                      format="HH:mm"
                      style="width:100%"
                      range-separator="至"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                      placeholder="选择时间范围"
                      :clearable="false"
                      is-range
                      @change="timeChange('timeBut')"
                  >
                  </el-time-picker>
                 <el-button :type="butStutas.timeBut" @click="allTimes" size="mini" class="allDay_but" plain icon="el-icon-check">全天</el-button>
              </div>
              
          </el-form-item>

          <el-form-item label="执行动作" prop="controlAction">
              <radioFun :formInfos='ctlForm' :list="iotActuatorFuns" @change="actionChange"></radioFun>
          </el-form-item>
          
          <el-form-item label="执行时长" prop="delay">
              
             
              <div class="d-flex align-items-center">
               <el-input
                  type="number"
                  style="width:220px"
                  class="input_box time_input text_center_input"
                  v-model.number="ctlForm.delay">
                  <el-select class="time-select_box" v-model="ctlForm.delayUnit" slot="append" placeholder="时间">
                    <el-option label="分" :value="2"></el-option>
                    <el-option label="时" :value="3"></el-option>
                  </el-select>
              </el-input>
              <HnRemarks class="remarks_box ml-6" width= '160'>
                
                 <p>设备执行一次动作的时间，0表示一直执行</p>
               
              </HnRemarks>
            </div>
          </el-form-item>

          <el-form-item label="执行条件">
            <div class="d-flex align-items-center">
                <el-radio-group v-model="ctlForm.linkageWay" @change="linkageWayChange">
                    <el-radio :label="item.value" v-for="item of $dictionary('linkageWay')" :key="item.value">{{ item.label }}</el-radio>
                </el-radio-group>
                <HnRemarks class="remarks_box" :content='remarksContent'></HnRemarks>
            </div>
          </el-form-item>

          <template v-if="isHasData">
            <el-form-item  :label="'联动条件'+(i + 1)" v-for="(item,i) of ctlForm.iotControlLinkageConditions" :key="i">
                <div class="joint_from_box">
                <div class="d-flex">
                <span class="sinleline dev_info_text">{{ dtosText(item)}}</span>
                <span class="ml-6 flex-shrink-0">{{ tremformatter(item.compareType) }}</span>
                <span class="ml-6 flex-shrink-0">{{ tremValue(item) }}</span>
                </div>
                <div>
                <span @click="toEditDtos(item)" class="el-icon-edit cp"></span>
                <span @click="toDelDtos(i)" class="el-icon-delete ml-6 cp"></span>
                <span @click="toAddDtos" class="el-icon-plus ml-6 cp " v-if="ctlForm.iotControlLinkageConditions.length == (i+1)"></span>
                </div>
            </div>
            <setDtos @submit="data => dtosEditSubmit(data,item)" @close='dtosColse(item)' :infos='item' :isEdit="true" v-if="item.isEdit"></setDtos>
            </el-form-item> 
          </template>
          <template v-if="isAddDtos || (!ctlForm.iotControlLinkageConditions || !ctlForm.iotControlLinkageConditions.length)">
            <el-form-item label="新增联动条件" prop="iotControlLinkageConditions">
                <setDtos @submit="dtosAddSubmit" :devId="devId" @close='dtosColse'></setDtos>
            </el-form-item> 
          </template>

          <el-form-item label="状态">
                <el-switch
                v-model="ctlForm.status"
                :active-value="1"
                :inactive-value="2"
                active-color="#00C395"
                inactive-color="#343F48">
                </el-switch>
                <span :class="['switch_text',ctlForm.status == 1 ? 'baseColor':'']">{{ ctlForm.status == 1 ? '启用':'停用' }}</span>
          </el-form-item> 
          
         <el-form-item class="but_box">
            <el-button type="warning" plain @click="resetForm('ctlForm')" size="mini" icon="el-icon-close">取消</el-button>
            <el-button type="primary" plain @click="submitForm('ctlForm')" size="mini" icon="el-icon-check">保存</el-button>
         </el-form-item> 
      </el-form> 
      <HnSyncLoading
          :loading="perLoading" 
          :erroStatus='erroStatus'
           width= '80'
           types="line">
      </HnSyncLoading>
  </div>
</template>

<script>
  import dtosMixin from './dtosMixin'
//   import termInput from './inputCron/termInput.vue'
//   import { deepClone , mapTo,getParentChildInfo } from '@/libs/util'
  import radioFun from '../../../radioFun.vue'
  import setDtos from '../../../assignment-model/components/setJointTask/setDtos.vue'
  import bus from '@/libs/eventBus'
  export default {
      name: 'paramFrom',
      mixins: [dtosMixin],
      components:{
        //  termInput,
         setDtos,
         radioFun
      },
      props:{
        devInfo: Object,
        linkageInfo: Object,
      },
      data(){
          const nowDate = this.$day().format('YYYY-MM-DD') 
          const loopMin = (rule, value, callback) => {
                let maxNub = this.ctlForm.delayUnit == 2 ? 1080 : 18
                if (typeof value !== 'number' || (value < 0 || value > maxNub)){
                    if(this.ctlForm.delayUnit == 2) callback(new Error('请输入18小时内，0-1080分钟的时间值'))
                    else callback(new Error('请输入0-18小时内的时间值'))  
                } 
                else {
                  callback()
                }
          }
          const arrayEmpty = (rule, value, callback) => {
                if (!Array.isArray(value)) callback(new Error('数据格式为数组'))
                if (value.length) {
                callback()
                } else {
                callback(new Error('请添加联动条件'))
                }
          }
          return{
              nowDate: nowDate,
              devSelectOptions:[],
              erroStatus: false,
              perLoading: false,
              remarksContent: '选择满足所有条件时，需要选择同一设备的执行器',
              ctlForm:{
                  id:'',
                  actuatorIds:'',
                  status:2,
                  eTime:'',
                  sTime: '',
                  controlAction:1,  // 执行动作
                  delayAction: '',
                  delay: 0,
                  delayUnit: 2,
                  beginTime:'',
                  endTime:'',
                  openPercent: 100,
                  linkageWay: 0,
                  iotControlLinkageConditions: [],
                  time: [nowDate,'2099-01-01'],  // 有效期 - 绑定值
                  timeSlot: ['00:00','23:59'], // 执行时间段 - 绑定值
              },
              isAddDtos: true,
              rules:{
     
                  delay: [
                    { validator: loopMin, trigger: 'blur', required: true }
                  ],
                  iotControlLinkageConditions: [
                    { validator: arrayEmpty, trigger: 'blur', required: true}
                  ]
                  // week: [
                  // { validator: arrayEmpty, trigger: 'blur', required: true}
                  
                  // ],
                  
                  // controlAction: [
                  //     { required: true, message: '请选择执行动作', trigger: 'blur' },
                  // ]
              },
              butStutas:{
                 dayBut: 'primary',
                 timeBut: 'primary'
              }
          }
      },
      watch:{
       
         linkageInfo:{
            handler (val){
                if(val) this.analysisData(val)
            },
            immediate: true
         }
      },
      computed:{
          tremformatter(){
              return val => {
                 return val == 2 ? '小于' : val == 4 ? '大于' : ''
              }
          },
          isHasData(){
            return this.ctlForm.iotControlLinkageConditions && this.ctlForm.iotControlLinkageConditions.length
          },
          isReversible(){
              return this.devInfo && this.devInfo ?.isReversible ? true : false
          },
          isEdit(){
            return !!this.linkageInfo || this.ctlForm.id
          },
          iotActuatorFuns(){
            if(!this.devInfo) return [] 
            const defaultFuns = this.defaultFuns
            return defaultFuns.concat(this.devInfo.iotActuatorFuns)
          },
          defaultFuns(){
            
            return this.devInfo.isReversible ? 
            [
              {
                funValue: 1,
                name: this.devInfo.openChannelName,
                openPercent: 100
              },
              {
                funValue: 2,
                name: this.devInfo.closeChannelName,
                openPercent: 100
              },
              {
                funValue: 0,
                name: '暂停',
                openPercent: 0
              }
            ] :
            [
              {
                funValue: 1,
                name: this.devInfo.openChannelName,
                openPercent: 100
              },
              {
                funValue: 0,
                name: this.devInfo.closeChannelName,
                openPercent: 100
              }
            ]
            
          }
      },
      methods:{
         allTimes(){
            this.$set(this.ctlForm,'timeSlot',['00:00','23:59'])
            this.butStutas.timeBut = 'primary'

          },
          actionChange(data){
            this.ctlForm.controlAction = data.controlAction
            this.ctlForm.openPercent = data.openPercent
          },
          linkageWayChange(){
                if(this.ctlForm.linkageWay == 1 && this.isHasData){
                  this.$confirm(`选择满足所有条件时，需要选择同一设备中的执行器，如果联动条件不匹配，会被清除数据，确认继续？`, "提示").then(()=> {
                      this.ctlForm.iotControlLinkageConditions = []
                      this.isAddDtos = true
                  }).catch(() => {
                    this.ctlForm.linkageWay = 0
                  })
                }
                
          },
          // 解析数据
          analysisData(){

            Object.entries(this.ctlForm).map(([key,val])=>{
                if(key == 'iotControlLinkageConditions'){
                    let list = this.linkageInfo.iotControlLinkageConditions
                    this.ctlForm[key] = []
                    list.map(item => {
                        let term = `${item.compareType}&${item.value}`
                        let {parentInfo,childrenInfo } = this.parentChildInfo(item.sensorId)
                        this.ctlForm[key].push({
                            compareType: item.compareType,
                            value: item.value,
                            sensorId: item.sensorId,
                            term: term,
                            id: item.id,
                            showData: {
                                parentInfo: {
                                    value: parentInfo ?.devId,
                                    label: parentInfo ?.devName,
                                },
                                childrenInfo:{
                                    value: childrenInfo ?.id,
                                    label: childrenInfo ?.name,
                                    unit: childrenInfo ?.enUnit
                                }
                            },
                            isEdit: false
                        })
                    })
                }else{
                    if(this.linkageInfo.hasOwnProperty(key)){
                        this.ctlForm[key] = this.linkageInfo[key]
                    } 
                }
            })
            // 判断当联动条件存在时，不显示新增联动
            if(this.ctlForm.iotControlLinkageConditions.length) this.isAddDtos = false
            // 时间段处理
            let { eTime,sTime } = this.ctlForm
           
            //  日期显示
            let eT =  eTime.slice(0,eTime.indexOf(' ')) 
            let sT =  sTime.slice(0,sTime.indexOf(' ')) 
            
            let beginTime =  this.slotTimeHanlde(this.ctlForm.beginTime),
                endTime = this.slotTimeHanlde(this.ctlForm.endTime);

            this.$set(this.ctlForm,'time',[sT,eT])
            this.$set(this.ctlForm,'timeSlot',[beginTime,endTime])   
            
            if(eT !== '2099-01-01') this.butStutas.dayBut = ''
            if(beginTime !== '00:00' || endTime !== '23:59') this.butStutas.dayBut = ''
       
          },
    
          // 整合数据
          mergeData(){
              let ctlForm = this.ctlForm
            //   let cloneData = deepClone(ctlForm)
              // 转化延时时间为数字
              // 处理开始结束日期
              let timeArr = ctlForm.time
          
              let conditions = ctlForm.iotControlLinkageConditions.map(item =>({
                compareType: item.compareType,
                value: item.value,
                sensorId: item.sensorId
              }))
              return {
                  id: ctlForm.id,
                  actuatorIds: [this.devInfo.id],
                  status: ctlForm.status,
                  eTime: timeArr[1],
                  sTime: timeArr[0],
                  controlAction: ctlForm.controlAction,  // 执行动作
                  delay: ctlForm.delay,
                  delayUnit: ctlForm.delayUnit,
                  beginTime: ctlForm.timeSlot[0],
                  endTime: ctlForm.timeSlot[1],
                  openPercent: ctlForm.openPercent,
                  linkageWay: ctlForm.linkageWay,
                  iotControlLinkageConditions: conditions,
              }
          },
          slotTimeHanlde(data){
             if(data.includes(':')){
                let strArr = data.split(":")
                return `${strArr[0]}:${strArr[1]}`
             }
          },
          resetForm(formName) {
             this.$refs[formName].resetFields();
            //   this.devInfo.visbise= false
             bus.$emit('colseDiolg')
          },
          submitForm(formName) {
              this.$refs[formName].validate((valid) => {
                if (valid) {
                    
                    let data = this.mergeData()
                    if(this.isEdit){
                        this.reqEditIotControlLinkage(data)
                    
                    }else{
                       this.reqAddIotControlLinkage(data)
                    }
                    
                
                } 
              });
          },
       
          // 新增
          async reqAddIotControlLinkage(data){
              this.perLoading = true
              const res = await this.$api.AddIotControlLinkageId(data)
              if(res){
                 setTimeout(()=>{
                      this.perLoading = false
                      this.ctlForm.id = res
                      this.devInfo.visbise= false
                 },1000)
                 this.$emit('update')
              }else{
                  this.erroStatus = true
              }
          },
          // 编辑
          async reqEditIotControlLinkage(data){
              this.perLoading = true
              const res = await this.$api.EditIotControlLinkage(data)
              if(res){
                 setTimeout(()=>{
                      this.perLoading = false
                      this.devInfo.visbise= false
                 },1000)
              }else{
                  this.erroStatus = true
              }
          },
          allDay(){
             this.ctlForm.time = [this.nowDate,'2099-01-01']
             this.butStutas.dayBut = 'primary'
          },
          timeChange(key){
            this.butStutas[key] = ''
          }

      }
      
  }
</script>

<style lang="scss" scoped>
.linkageFrom{
  box-sizing: border-box;
//   padding-right: 20px;
 .demo-ruleForm{
      width: 100%;
      .but_box{
          text-align: right;
          margin-bottom: 0 !important;
      }
      .termTime_box{
          display: flex;
          align-items: center;
          .allDay_but{
              margin-left: 10px;
          }
      }
  
  }
  .joint_from_box{
     display: flex;
     justify-content: space-between;
     color: #fff;
     .dev_info_text{
        max-width: 200px;
        display: inline-block;
        // line-height: normal;
     }
     
  }
  .ml-6{
    margin-left: 6px;
  }
  .switch_text{
   margin-left: 12px;
   color: #999;
 }
 .input_box ::v-deep .el-input__inner{
       text-align: center;
  }
  .unit_box{
       margin-right: 5px;
   }
   .el-radio-group ::v-deep .el-radio{
     margin-right: 20px !important;
   }
}
.time-select_box{
  width: 80px;
  background-color: #344552;
  border-left: 2px solid #28353F;
  border-radius: 0 5px 5px 0;
  color: #fff;
}
.time_input ::v-deep .el-input-group__append{
     border: none;
     background-color: #344552;
}

.text_center_input ::v-deep .el-input__inner{
    text-align: center;
    color: #fff;
}
</style>