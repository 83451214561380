<template>
   
  <div class="wh100 d-flex justify-content-center align-items-center">
    <template v-for="(item,i) of list">
      <itemInfo :key="item.id" :taskId="taskId" :infos="item" :index="i" :class="i > 0 ?'ml-70':''"></itemInfo>
    </template>
  
    <addInfo class="ml-70" @click="toAdd" :isJoint="true" v-if="taskLength == 1"></addInfo>
    
    
    <hnEmptyData setType="col" size="160px" text="暂无联控设置" v-if="!taskLength">
      <el-button size="mini" @click="toAdd" class="update_primary_but" type="primary" plain>立即新增</el-button>
    </hnEmptyData> 
  </div>
</template>

<script>
  import fromItem from './fromItem.vue'
  import addInfo from '../setTimeTask/addInfo.vue'
  import itemInfo from './itemInfo.vue'
  export default {
    components: { fromItem,addInfo,itemInfo },
    props:{
       list:{
          type: Array,
          default:() => []
       },
       taskId: String
     },
     computed:{
        taskLength(){return this.list.length } 
     },
     methods:{
      toAdd(){
         this.showDialog(fromItem,{
          props:{
            taskId: this.taskId
          }
         },{
          width:'520px',
          title: '新增联动控制',
        },()=> {
            
        })
       },
     }
  }
</script>

<style lang="scss" scoped>
.ml-70{
  margin-left: 70px;
}
</style>