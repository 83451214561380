<template>
    <div class="term_box">
        <!-- <el-select v-model="termInfo.term" class="selectAction" @change="termChange" placeholder="执行条件">
            <el-option
            v-for="item in termSelectOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
        </el-select> -->
        <el-radio-group v-model="termInfo.term" @change="termChange">
                    
          <el-radio :label="item.value" v-for="item of termSelectOptions" :key="item.value">{{ item.label }}</el-radio>

        </el-radio-group>
        <el-input
            @change="termChange"
            placeholder="条件值"
            class="input_box"
            :style="unitStyle"
            v-model="termInfo.val">
            <span slot="suffix" class="unit_box">{{ unit }}</span>
        </el-input>
        <HnRemarks class="remarks_box" :content='remarksContent'></HnRemarks>
    </div>
</template>

<script>
    import { dictionary } from '@/libs/dictionary' 
    import { devCodeAndId } from '@/libs/util'
    import emitter from "element-ui/src/mixins/emitter";
    export default {
        mixins: [emitter],
        props:{
           value: String,
           unit: String,
        },
        data(){
            return {
                termInfo:{
                    term: 2,
                    val:''
                },
                termSelectOptions: [
                    {
                        value: 2,
                        label: '小于'
                    },
                    {
                        value: 4,
                        label: '大于'
                    }
                ],
                remarksContent: '当关联设备参数值，满足执行条件时，执行相关动作。'
            }
        },
        watch:{
           value(val){
              if(val) this.analysisData()
              else{
                this.termInfo.term = 2
                this.termInfo.val = ''
              }
           },
        },
        computed:{
           unitStyle(){
               let width = '100px'
               if(this.unit){
                   let length = this.unit.length
                   if(length && length >= 3)
                      width = (100 + (length - 3) * 15) + 'px'
               }
               return { width }
           }  
        },
        created(){
        
            if(this.value) this.analysisData()
              
            
        },
        methods:{
            // 解析数据
            analysisData(){
                let info = devCodeAndId(this.value)
                if(info){
                    this.$set(this.termInfo,'term',parseInt(info.devId))
                    this.$set(this.termInfo,'val',info.devCode)
                }
            },
            // 合并数据
            mergeData(){
            
                let { term, val } = this.termInfo
                if( (term == 0 || !!term) && !!val){
                    let result = ''
                    let data = {
                        devId: term,
                        devCode: val
                    }
                    result= devCodeAndId(data)
                    this.$emit('input',result)
                    this.dispatch("ElFormItem", "el.form.blur", [result]);
                }
                
            },
            termChange(){
               
                this.mergeData()
            }
        }
    }
</script>

<style lang="scss" scoped>
 .term_box{
     width: 100%;
     display: flex;
     align-items: center;
     .selectAction{
         flex: 1;

     }
     .input_box{
         width: 140px !important;
         margin-left: 20px;
         flex-shrink: 0;
     }
     .unit_box{
         margin-right: 5px;
     }
     .selectAction ::v-deep .el-input__inner{
         text-align: center;
     }
     .input_box ::v-deep .el-input__inner{
         text-align: center;
     }
     .remarks_box{
         margin-left: 5px;
         flex-shrink: 0;
     }
 }
</style>