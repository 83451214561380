<template>
  <div class="dev_control_box">
     <div class="tabs_box">
       <div 
         v-for="item in tabsList" 
         :key="item.active" 
         @click="tabChange(item.active)"
         :class="ctlActivePageCode == item.active ? 'tab_active': ''">{{ item.name }}</div>
     </div>
     <div class="dev_content_box video-box-bg-image">
       <component :is="ctlActivePageCode"></component>
     </div>
  </div>
</template>

<script>
  import devControl from './components/dev-control'
  import assignmentModel from './components/assignment-model'
  import growModel from './components/grow-model'

  export default {
    components: {devControl,assignmentModel,growModel},
     data(){
      return{
         tabsList:[
           {
             name: '设备控制',
             active: 'devControl'
           },
           {
            name: '任务模型',
            active: 'assignmentModel'
           },
          //  {
          //   name: '生长模型',
          //   active: 'growModel'
          //  }
         ]
      }
     },
     created(){

      this.global_websocket_connect()
      
      this.$store.dispatch('reqIotDevSensorChooseList')
     },
     destroyed(){
       this.$store.commit('disconnectClient') 
     },
     computed:{
       client() { return this.$store.getters.stompClient },
       ctlActivePageCode() { return this.$store.getters.ctlActivePageCode }
     },
     methods:{
        tabChange(code){
          this.$store.commit('setCtlPageStutas',{pageType: code})
        }
     }
  }
</script>

<style lang="scss" scoped>
.dev_control_box{
  width: 100%;
  height: 100%;
  padding: 20px 20px 10px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .tabs_box{
    flex-shrink: 0;
    width: 200px; //200
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto 10px auto;
    & > div{
       padding-bottom: 10px;
       border-bottom: 2px solid transparent
    }
    .tab_active{
      position: relative;
      border-bottom: 2px solid var(--el-color-primary);
    }
    .tab_active::after{
      content: ' ';
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 0;
      border-left: 3px solid transparent;  
      border-right: 3px solid transparent;  
      border-bottom: 6px solid var(--el-color-primary); 
    }
  }
  .dev_content_box{
     flex: 1;
     padding:35px 40px;
     box-sizing: border-box;
     min-height: 0;
     white-space: normal;
  }
}

</style>