export default {
     data(){
         return {
            minutesText:"",
            hoursText: '',
            secondsText: '',
            weeksText:'',
         }
     },
     computed:{
        cronStr() {
            return `${this.secondsText || "0"} ${this.minutesText || "0"} ${this.hoursText || "*"} ? * ${this.weeksText || "*"}`;
                   
        }
     },
     methods:{
        //  处理周 cron => data
        weeksCronToData(cron){
            let val = cron.split(" ")[5];
            if(val.includes(',')){
               let activeWeek = val.split(',')
               return activeWeek
            }
            if(val && val != '*'){
                return [val]
            }
            return []
        },
        //  处理秒 cron => data
        secondsCronToData(cron){

        },
        //  处理分 cron => data
        minutesCronToData(cron) {

        },
        //  处理小时 cron => data
        hoursCronToData(cron) {

        },
        // 秒分时 cron => data 
        MHCronToData(cron) {
            let cronArr = cron.split(" ")
            let M = cronArr[1],H = cronArr[2];
            let result = ''
            let Hnub = 0,Mnub = parseInt(M);
            if(H == '*' && M.includes("/")){
                result = M.split("/")[1]; 
                return parseInt(result) 
            }
            if(M == '0' && H.includes("/")){
                Hnub = H.split("/")[1]; 
                result = parseInt(Hnub) * 60
                return result
            }
            if(Mnub > 0 && H.includes("/")){
                Hnub = H.split("/")[1]; 
                result = (parseInt(Hnub) * 60) + Mnub
                return result
            }
            return `${H}:${M}` 
                 
        },
        // 秒分时 data => cron
        SMHDataToCron(data){
            if(data > 0 && data <= 59 ){
                this.minutesText= `0/${data}`
                return
            }
            if(data > 59){
               let remainderNub = data%60
               let parseNub = parseInt(data/60)
               if(remainderNub == 0){
                   this.minutesText= `0`
                   this.hoursText = `0/${parseNub}`
               }else{
                   this.minutesText= remainderNub
                   this.hoursText = `0/${parseNub}`
               }
            } 
        },
        // 周 data => cron
        weeksDataToCron(data){
            
            this.weeksText = data.join(',')
        }
        
     } 
}