<template>
  <div class="timeFrom_box">
    
     <div class="from_box">
         <el-form :model="ctlForm" :rules="rules" ref="ctlForm" size="small" label-width="100px" class="demo-ruleForm">
          <el-form-item label="模式选择" prop='isLoop'>
            <div class="d-flex align-items-center">
              <el-radio-group v-model="ctlForm.isLoop" size="mini">
                <el-radio :label="true" border>循环模式</el-radio>
                <el-radio :label="false" border>定时模式</el-radio>
              </el-radio-group>
              <HnRemarks class="remarks_box" width= '240'>
                <div class="remarks_content_box">
                  <p>
                    <span class="baseColor">循环模式：</span>
                      有效期内，在选择的日期中，按时间间隔循环执行控制动作，并可延时动作。
                  </p>
                  <p>
                    <span class="baseColor">定时模式：</span>
                    有效期内，在选择的日期中，按设定时间执行控制动作，并可延时动作。
                  </p>
                </div>
              </HnRemarks>
            </div>
          </el-form-item>

          <el-form-item label="有效期" prop="time">
            <div class="termTime_box">
                  <el-date-picker
                  v-model="ctlForm.time"
                  type="daterange"
                  align="right"
                  value-format="yyyy-MM-dd"
                  format="yyyy 年 MM 月 dd 日"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  style="width: 100%"
                  @change="dayChange('dayBut')"
                  :clearable="false"
                  >
                  </el-date-picker>
                  <el-button :type="butStutas.dayBut" @click="allDay" size="mini" class="allDay_but" plain icon="el-icon-check">长期执行</el-button>
            </div>
          </el-form-item>

          <el-form-item label="日期选择" prop='week'>
              <week v-model="ctlForm.week"></week>
          </el-form-item>

          <el-form-item prop="controlAction" label="执行操作">
              <radioFun :formInfos='ctlForm' :list="iotActuatorFuns" @change="actionChange"></radioFun>
          </el-form-item>

          <template v-if="ctlForm.isLoop">
              <el-form-item label="执行频率" prop="loopTime">
                  <div class="d-flex align-items-center">
                    <el-cascader
                        style="width:220px"
                        v-model="ctlForm.loopTime"
                        :options="loopTimeOption"
                        :show-all-levels="false"
                        ></el-cascader>
                    <HnRemarks class="remarks_box ml-6" width= '240'>
                      <p>循环模式下，设备执行的周期频率时间，不能小于执行时长</p>
                    </HnRemarks>
                  </div>
              </el-form-item>
          </template>
          <template v-else>
              <el-form-item label="执行时间" prop="implementTime">
                  <el-time-picker
                      v-model="ctlForm.implementTime"
                      placeholder="时间"
                      value-format="HH:mm"
                      format="HH:mm"
                      :clearable="false"
                  >
                  </el-time-picker>
              </el-form-item>
          </template>

          <el-form-item label="执行时长" prop="delay">
            <div class="d-flex align-items-center">
              <el-input
                  type="number"
                  style="width:220px"
                  class="input_box time_input text_center_input"
                  v-model.number="ctlForm.delay">
                  <el-select class="time-select_box" v-model="ctlForm.delayUnit" slot="append" placeholder="时间">
                    <el-option label="分" :value="2"></el-option>
                    <el-option label="时" :value="3"></el-option>
                  </el-select>
              </el-input>
              <HnRemarks class="remarks_box ml-6" width= '240'>
                
                 <p>设备执行一次的时间，不能大于执行频率，0表示一直执行</p>
               
              </HnRemarks>
            </div>
          </el-form-item>
          <el-form-item label="状态">
              <el-switch
              v-model="ctlForm.status"
              :active-value="1"
              :inactive-value="2"
              active-color="#00C395"
              inactive-color="#343F48">
              </el-switch>
              <span :class="['switch_text',ctlForm.status == 1 ? 'baseColor':'']">{{ ctlForm.status == 1 ? '启用':'停用' }}</span>
          </el-form-item> 

          <el-form-item class="but_box">
              <el-button type="warning" plain @click="resetForm('ctlForm')" size="mini" icon="el-icon-close">取消</el-button>
              <el-button type="primary" plain @click="submitForm('ctlForm')" size="mini" icon="el-icon-check">保存</el-button>
          </el-form-item> 
         </el-form> 
     </div>
     <HnSyncLoading :loading="perLoading" :erroStatus='erroStatus' width='80' types="line"/>
 
</div>
</template>

<script>
import week from '@/components/localComponent/week.vue'
// import timeSelect from '@/components/timeSelect.vue'
import cronMixin from './inputCron/cronMixin'
import { mapTo } from '@/libs/util'
import radioFun from '../../../radioFun.vue'
import bus from '@/libs/eventBus'
export default {
mixins: [cronMixin],
components: {
  week,
  radioFun
},
props: {
  devInfo: Object,
  planInfo: Object,
},
data () {
  const nowDate = this.$day().format('YYYY-MM-DD') 
  const nowTime = this.$day().format('HH:mm') 
  
  const arrayEmpty = (rule, value, callback) => {
    if (!Array.isArray(value)) callback(new Error('数据格式为数组'))
    if (value.length) {
      callback()
    } else {
      callback(new Error('请选择日期'))
    }
  }
  const delayTime = (rule, value, callback) => {
        // if(this.formInfos.loopTime && this.formInfos.loopTime.length){
        //    let [loopType,loopData] = this.formInfos.loopTime
        //    const loopMinTime = loopType == 'hours' 0? 
        //    const delayMinTime = 
        // }
        let maxNub = this.ctlForm.delayUnit == 2 ? 1080 : 18
        if (value !== 0 && !value || (value < 0 || value > maxNub)){
            if(this.ctlForm.delayUnit == 2) callback(new Error('请输入18小时内，0 -1080分钟的时间值'))
            else callback(new Error('请输入0-18小时内的时间值'))  
        } 
        else {
          callback()
        }
  }
  return {
    nowDate: nowDate,
    ctlForm: {
      id: '',
      actuatorIds: [], // 执行器id 
      isLoop: true,  // 是否循环
      status: 2,   // 状态 1 启用 2 停用
      sTime: '',   // 有效开始日期
      eTime: '',   // 有效结束日期
      controlAction: 1, // 控制动作
      cron: '',  // 表达式
      delay: 0, // 延时时间
      delayUnit: 2, // 延时时间单位
      implementTime: nowTime,
      loopTime: ['min',5],
      // loopHours: '12:00',
      time: [nowDate,'2099-01-01'],  // 有效期 - 绑定值
      week: ['1','2','3','4','5','6','7'],
      openPercent: 100
    },
    erroStatus: false,
    perLoading: false,
    // checkTime: '3',
    rules: {
      week: [
        { validator: arrayEmpty, trigger: 'blur', required: true }

      ],
      loopHours: [
              { required: true, message: '请选择执行时间', trigger: 'blur' },
      ],
      delay: [
         { validator: delayTime, trigger: 'blur', required: true },
      ],
      implementTime: [
        { required: true, message: '请选择执行时间', trigger: 'blur' },
      ],
     
    },
    loopTimeOption: [
        {
          value: 'min',
          label: '分钟',  
          children: [
             {
              value: 5,
              label: '5分钟'
             },
             {
              value: 10,
              label: '10分钟'
             },
             {
              value: 15,
              label: '15分钟'
             },
             {
              value: 20,
              label: '20分钟'
             },
             {
              value: 30,
              label: '30分钟'
             },
          ]
        },
        {
          value: 'hours',
          label: '小时',  
          children: [
            {
              value: 1,
              label: '1小时'
             },
             {
              value: 2,
              label: '2小时'
             },
             {
              value: 3,
              label: '3小时'
             },
             {
              value: 4,
              label: '4小时'
             },
             {
              value: 5,
              label: '5小时'
             },
             {
              value: 6,
              label: '6小时'
             },
             {
              value: 7,
              label: '7小时'
             },
             {
              value: 8,
              label: '8小时'
             },
             {
              value: 9,
              label: '9小时'
             },
             {
              value: 10,
              label: '10小时'
             },
             {
              value: 11,
              label: '11小时'
             },
             {
              value: 12,
              label: '12小时'
             }
          ]
        }
    ],
    // minutesText:'',
    // hoursText:'',
    // delayTimeType: '3',
    // ctlTimeType: '3',
    butStutas:{
        dayBut: 'primary',
        timeBut: 'primary'
    }
  }
},
computed: {
  isReversible () {
    return this.devInfo && this.devInfo ?.isReversible ? true : false
  },
  isEdit () {
    return !!this.planInfo
  },
  iotActuatorFuns(){
     if(!this.devInfo) return [] 
     const defaultFuns = this.defaultFuns
     return defaultFuns.concat(this.devInfo.iotActuatorFuns)
  },
  defaultFuns(){
    return this.devInfo.isReversible ? 
    [
      {
        funValue: 1,
        name: this.devInfo.openChannelName,
        openPercent: 100
      },
      {
        funValue: 2,
        name: this.devInfo.closeChannelName,
        openPercent: 100
      },
      {
        funValue: 0,
        name: '暂停',
        openPercent: 0
      }
    ] :
    [
      {
        funValue: 1,
        name: this.devInfo.openChannelName,
        openPercent: 100
      },
      {
        funValue: 0,
        name: this.devInfo.closeChannelName,
        openPercent: 100
      }
    ]
     
  }
},
watch: {

  planInfo (val) {

    if (val) this.analysisData(val)
  },
  'ctlForm.isLoop':{
      handler(val){
          this.$emit('updateHeight')
      },
      immediate: true
  }
},
created () {

  if (this.planInfo) this.analysisData(this.planInfo)
},

methods: {
 
  actionChange(data){
      this.ctlForm.controlAction = data.controlAction
      this.ctlForm.openPercent = data.openPercent
  },
  submitForm (formName) {
    this.$refs[formName].validate((valid) => {
      if (valid) {
        let data = this.mergeData()
        if (this.isEdit) {
          data.planId = this.planInfo.id
          this.reqEditIotControlPlan(data)

        } else {
          this.reqAddIotControlPlan(data)
        }
      } 
    });
  },
  // 新增
  async reqAddIotControlPlan (data) {
    this.perLoading = true
    const res = await this.$api.AddIotControlPlan(data)
    if (res) {
      setTimeout(() => {
        this.perLoading = false
        this.devInfo.visbise = false
      }, 1000)
      this.$emit('update')
    } else {
      this.erroStatus = true
    }
  },
  // 编辑
  async reqEditIotControlPlan (data) {
    this.perLoading = true
    const res = await this.$api.EditIotControlPlan(data)
    if (res) {
      setTimeout(() => {
        this.perLoading = false
        this.devInfo.visbise = false
      }, 1000)
    } else {
      this.erroStatus = true
    }
  },
  // 整合请求数据
  mergeData () {

    let ctlForm = this.ctlForm

    // 转化周数据    
    this.weeksDataToCron(ctlForm.week)
    // 处理日期数据
    this.timeDataToCron()

    // 处理开始结束日期
    let timeArr = ctlForm.time
    
    return {
      id: ctlForm.id,
      actuatorIds: [this.devInfo.id], // 执行器id 
      isLoop: ctlForm.isLoop,  // 是否循环
      status: ctlForm.status,   // 状态 1 启用 2 停用
      sTime: timeArr[0],   // 有效开始日期
      eTime: timeArr[1],   // 有效结束日期
      controlAction: ctlForm.controlAction, // 控制动作
      cron:this.cronStr,  // 表达式
      delay: ctlForm.delay, // 延时时间
      delayUnit: ctlForm.delayUnit, // 延时时间单位
      openPercent: ctlForm.openPercent
    }
  },
  hanldeTime(cron){

      if(!cron) return
      let cronInfo = cron.split(" ")
      let minStr = cronInfo[1]
      let hoursStr = cronInfo[2]
      let weekStr = cronInfo[5]
      let returnData = {
        // loopHours: '12:00',
        loopTime: ['min',5],
        implementTime: this.$day().format('HH:mm'),
        week: [],
        isLoop: true
      }
      // 1.判断是否为循环任务
      
      const isMinLoop = minStr.includes('/')
      const isHoursLoop = hoursStr.includes('/')
      returnData.isLoop = isMinLoop || isHoursLoop
      // 2.处理循环任务
      if(returnData.isLoop){
        returnData.loopTime = isMinLoop ? ['min', parseInt(minStr.split('/')[1]) ] : isHoursLoop ? ['hours', parseInt(hoursStr.split('/')[1]) ] : ['min',5]
      }else{
        returnData.implementTime = `${hoursStr}:${minStr}`
      }
      // 3.处理周
      if(weekStr.includes(',')){
          returnData.week = weekStr.split(',')
      }else if(weekStr && weekStr != '*'){
          returnData.week = [weekStr]
      }
      return returnData
  },
  // 解析数据
  analysisData (infos) {
    let {  eTime, sTime, cron } = infos
    let timeData =  this.hanldeTime(cron)
    let analysisInfos = Object.assign({},infos,timeData)
    
    mapTo(analysisInfos,this.ctlForm)
    //  日期显示
    let eT = eTime.slice(0, eTime.indexOf(' '))
    let sT = sTime.slice(0, sTime.indexOf(' '))
    this.$set(this.ctlForm, 'time', [sT, eT])
    if(eT !== '2099-01-01') this.butStutas.dayBut = ''
 
  },
  resetForm (formName) {
    
    this.$refs[formName].resetFields();
    bus.$emit('colseDiolg')
    // this.devInfo.visbise = false
    

  },
  timeChange (data) {
        
    if (data) {
      let dataList = data.split(':')
      this.minutesText = dataList[1]
      this.hoursText = dataList[0]
    }
  },
  loopTimeChange() {
   let [loopTimeType,timeData] = this.ctlForm.loopTime
   if(loopTimeType == 'min'){
     this.minutesText = timeData ? `0/${timeData}` : 0  
     this.hoursText = '*'
     return
   }
   if(loopTimeType == 'hours'){
     this.hoursText =  timeData ? `0/${timeData}` : 0 
     this.minutesText = ''
   }
    // if (data) {
    //   let dataList = data.split(':')
    //   this.hoursText = dataList[0]
    //   this.minutesText = this.ctlForm.loopTime ? `0/${this.ctlForm.loopTime}` : 0
    // }
  },
  timeDataToCron(){
    if(this.ctlForm.isLoop){
        this.loopTimeChange()
    }else{
        this.timeChange(this.ctlForm.implementTime)
    }
  },
  allDay(){
      this.ctlForm.time = [this.nowDate,'2099-01-01']
      this.butStutas.dayBut = 'primary'
  },
  dayChange(key){
     this.butStutas[key] = ''
  }
}
}
</script>

<style lang="scss" scoped>

.from_box {
width: 100%;
//  height: 480px;
.demo-ruleForm {
  width: 100%;
  .but_box {
    text-align: right;
    margin-bottom: 0 !important;
  }
}
.input_box ::v-deep .el-input__inner {
  text-align: center;
}
.unit_box {
  margin-right: 5px;
}
//  & > div{
//      height: 100%;
//  }
}
.switch_text {
margin-left: 12px;
color: #999;
}
.el-radio-group ::v-deep .el-radio{
 margin-right: 20px !important;
}
.fade-enter-active,
.fade-leave-active {
transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
opacity: 0;
}
.el-date-editor{
border-radius: 3px;
}
.remarks_content_box{
& > p:last-child{
  margin-top: 14px;
}
}
.time-select_box{
  width: 80px;
  background-color: #344552;
  border-left: 2px solid #28353F;
  border-radius: 0 5px 5px 0;
  color: #fff;
}
// .time-select_box
.time_input ::v-deep .el-input-group__append{
     border: none;
     background-color: #344552;
}

.text_center_input ::v-deep .el-input__inner{
    text-align: center;
}
.termTime_box{
    display: flex;
    align-items: center;
    .allDay_but{
        margin-left: 10px;
    }
}
</style>