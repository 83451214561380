<template>
  <div class="control_model_box">
    <div class="ctl_menu_box">
      <autoSearch 
       ref="autoSearch"
       placeholder="请输入关键字"
       @select="handleSearch"
       @clear="clearSearch"
       :isScrollMargin="true"
       :fetch-suggestions="querySearch" 
       size="small" 
       class="mb-10">
       <template slot-scope="{ row }">
          <div class="auto_select_box">
              <span :class="['iconfont', row.iconCls || 'icon-tongdaozhuangtai', 'baseColor','autoIcon']"></span>
              <div class="info_box">
                  <p>{{ row.value }}</p>
                  <p>{{ row.devCode }}</p>
              </div>
          </div>
      </template>
      </autoSearch>
      <Menu
      class="menu_box"
      @change='devChange'
      :groupState='false'
      :defaultOpenId='ctlActiveMenuInfo'
      :menus="navMenus"
      ></Menu>
    </div>
    <container class="ctl_content_box">
       <div slot="head" class="d-flex justify-content-between"> </div>
      
         <warnBoard slot="h-left" @update="LogInsectLampAlarmContentList" :listData ='warnList' ref="warnRef" :devId='devActiveId' v-if="warnList && warnList.length"></warnBoard>            
         <div slot="h-center" :class="devStutasText">{{ devName }}</div>
         <template slot="h-right">
          <batchCtl :devId="devActiveId" @ctl="batchCtl" v-if="autoCtlMode"></batchCtl>
          <el-button size="small" class="ml-10 update_primary_but" type="primary" @click="toUpdate" :loading="updateLoading" plain>刷新</el-button>
         </template>
         
        <paramRoll :listData="devRealTimeListData" ref="rollRef" v-if="devRealTimeListData && devRealTimeListData.length"></paramRoll>
        <actuatorList :isOldDev="isOldDev" :devInfo="activeDevInfo" 
        ref="actuatorRef" @update="toUpdateActuatorList" :ctlDevList="ctlDevList"></actuatorList> 
       
    </container>
    
  </div>
</template>

<script>
  import autoSearch from '@/components/localComponent/autoSearch.vue';
  import Menu from './compoents/menu.vue'
  import container from '../container.vue'
  import warnBoard from '../warnBoard.vue'
  import paramRoll from '../param-roll.vue'
  import actuatorList from '../actuator-list'
  import batchCtl from '../batch-ctl.vue'
  import config from '@/config'
  import { deepClone,todo } from '@/libs/util.js'
  
  export default {
     components: { autoSearch,Menu,container,warnBoard,paramRoll,actuatorList,batchCtl},
     data(){
        return{
          // activeMenuInfo: null, // 激活菜单项
          menuDevType: ['控制设备','采控设备'],
          navMenus: [],
          copyMenus: [],
          activeDevInfo: null,
          onlineData: config.devStatus.online,
          batchCtlVal: '',
          warnList: [],
          devRealTimeListData: [],
          ctlDevList: [],
          isLoading:false,
          devFormData: null,
          activeTopic: null, //当前订阅对象
          updateLoading: false
        }
     },
     created(){
       
       this.reqMenu()
     },
    
     computed:{
       ctlActiveMenuInfo(){
          return this.$store.getters.ctlActiveMenuInfo
       },
       isOnline() {
            let info = this.activeDevInfo
            return info ? info.onlineStatus : false
       },
       autoCtlMode(){
          //  是否自动模式
          return this.activeDevInfo ?.autoCtlMode == 0 ? true : false
       },
       devStutasText(){
          return this.isOnline == this.onlineData ? 'baseColor' : this.isOnline == 2 ? 'standbyColor': 'offLineColor'
       },
       reqData() {
          let menuType = this.menuDevType
          let result = null
          if(menuType && menuType.length){
            const menuDevType = config.menuDevType 
            let arrList = []
            menuType.map(item => {
                  let data = menuDevType[item]
                  if(data) arrList.push(data)
            })
            result = { devTypes: arrList,actuatorFun:2 }
          
          }  
          return result
       },
       devName(){
         return this.activeDevInfo ?.devName || ''
       },
       devActiveId(){
         return this.activeDevInfo ?.devId || ''
       },
       isOldDev() {
           let vCode = this.activeDevInfo ?.protocolVersion  // 大于0 是新的
           return vCode <= 0 
           
        },
     },
     methods:{
         
        async reqMenu() {
          try{
            const res = await this.$api.GroupDeviceList(this.reqData)
           
            if(res){
                this.navMenus = res
                this.copyMenus = res  // 用于搜索还原
                return res
            } 
          }catch{
              return false
          }  
            
        },
        querySearch(queryString, cb) {
                
            var restaurants = []
            if(this.navMenus.length){
                  this.navMenus.forEach(item => {
                      if(item.devs && item.devs.length){
                        item.devs.forEach(val => {
                            // value 和 Id 为搜索组件绑定值，key不能更改
                            restaurants.push({
                                parentId: item.id,
                                devId: val.devId,
                                value: val.devName,
                                devCode: val.devCode,
                                id: val.id,
                                onlineStatus: val.onlineStatus,
                                iconCls: val.iconCls
                            })
                        })
                      }
                  })
            }
            this.restaurants = restaurants
            var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
            // 调用 callback 返回建议列表的数据
          
            cb(results);
        },
        createFilter(queryString) {
          return (restaurant) => {
              // 这里可以通过value 和 id一起判断
            return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1 ||
                    restaurant.devCode.toLowerCase().indexOf(queryString.toLowerCase()) > -1
          };
        },
        handleSearch(data) {
          let arrList = []
          this.navMenus.some(item => {
              if(item.id == data.parentId){
                  let copyItem = deepClone(item)
                  // this.activeMenuInfo = data
                  this.$store.commit('setCtlPageStutas',{devMenuInfo: data.devId})
                  copyItem.devs =  todo(copyItem.devs,data.devId)
                  arrList.push(copyItem)
                  return true
              } 
          })
          
          this.navMenus =  arrList

        },
        clearSearch() {
            this.$refs.autoSearch.active(true)
            // this.activeMenuInfo = null
            this.$store.commit('setCtlPageStutas',{devMenuInfo: ''})
            this.navMenus = this.copyMenus
        },
        
        batchCtl(info){
           this.$refs.actuatorRef.batchCtl(info)
        },
        devChange(infos) {
            this.isLoading = true
            if(this.activeDevInfo) this.pauseTopic(this.activeDevInfo.devCode)
            this.activeDevInfo = this.getDevInfo(infos)

            this.startTopic() // 订阅设备
            let data = infos[0] 
            if(data){
                let { devId } = data
                this.devFormData = data
                this.$store.commit('setCtlPageStutas',{devMenuInfo: devId})
                this.LogInsectLampAlarmContentList() // 告警信息
                this.reqIotDeviceRealTimeDataByCacheList(data) // 实时参数
                this.reqIotDeviceControlList(data)  // 控制执行器列表
            }
            
        },
        // 开始订阅
        startTopic(){
   
          if(this.isOldDev) return

          this.global_websocket_receive_message(this.activeDevInfo.devCode, this.sourceCallback)
          
        },
        // 暂停订阅
        pauseTopic(topic){
         
          if(this.isOldDev) return
          this.global_websocket_unsubscribe(topic)
        },
        // 订阅回调
        sourceCallback(frame){
           this.$refs.actuatorRef ?.sourceCallback(frame)   
        },
        // 更新列表
        toUpdateActuatorList(){
          this.reqIotDeviceControlList(this.devFormData)
        },
        toUpdate(){
          this.updateLoading = true
          this.reqIotDeviceControlList(this.devFormData)

        },
        // 获取告警内容
        async LogInsectLampAlarmContentList(){
     
            const res = await this.$api.LogInsectDevAlarmContentList({id: this.devActiveId})
            if(res && res.length){
              this.warnList = res || []
              this.$nextTick(() => { 
    
                  this.$refs.warnRef ?.updateData()
              })
            }else this.warnList = []

        },
        // 实时数据页面加载
        async reqIotDeviceRealTimeDataByCacheList(data){
            const res = await this.$api.IotDeviceRealTimeDataByCacheList(data)
            this.devRealTimeListData = res ?.devRealTimeDatas || []
            this.$refs.rollRef ?.update()  
        },
        // 设备执行器查询
        async reqIotDeviceControlList(data){
            const res = await this.$api.IotDeviceControlList(data)
            if(this.updateLoading){
              this.$refs.actuatorRef.taskQueueInfo = []
              setTimeout(() => {
                this.updateLoading = false
                this.$msg.success('刷新成功')
              }, 500);
            }
            const isData = res && res.length
            if(isData) res.forEach(item =>{
              item.visbise = false  // 控制智能控制表单弹窗是否显示
              item.isLoading = false  // 控制执行器执行动画是否显示
              item.pauseLoading = false // 控制暂停按钮加载动画是否显示
              item.isCtlPause = true // 控制暂停按钮是否显示 
              item.isErrorLoading = false // 控制失败加载动画
              item.showPause = false // 是否显示暂停按钮
              return item
            } )
            this.ctlDevList = isData ? res : []
            // this.$nextTick(()=>{
            //   this.$refs.actuatorRef.updateStomp()
            // })
            // console.log(this.ctlDevList)
        },
        getDevInfo(infos){
            let [info,menus] = infos
            let result = ''
            menus.some(item => {
                if(item.devs && item.devs.length) {
                   return item.devs.some(ele => {
                       if(info.devId == ele.devId && info.id == ele.id){
                           result = ele
                           return true
                       }   
                   })
                }
            })
            return result

        }
       
     }
  }
</script>

<style lang="scss" scoped>
.control_model_box{
  height: 100%;
  display: flex;
  .ctl_menu_box{
     height: 100%;
     width: 230px;
     .menu_box{
       height: calc(100% - 44px);
     }
  }
  .ctl_content_box{
     height: 100%;
     flex: 1;
     min-width: 0;
     white-space: normal;
  }
}

</style>