<template>
  <div class="autoCtl_box">
      <div class="tabs_header_box">
          <ul>
            <li 
             :class="[tabActive == item.code? 'tabActive': '','d-flex align-items-center']"
             @click="navChange(item.code)"
             v-for="item of navList" 
             :key="item.code">
               <span>{{ item.name }}</span>
               <HnRemarks class="remarks_box ml-6" width= '240'>
                <div class="remarks_content_box">
                  <p v-if="item.code == 0">
                    <span class="baseColor">定时控制：</span>
                    定时执行相关控制器操作，支持循环模式和定时模式，最多支持同时创建二个定时任务
                  </p>
                  <p v-else>
                    <span class="baseColor">联动控制：</span>
                    设置关联设备的触发值，当达到条件值时，触发执行器执行动作
                  </p>
                </div>
              </HnRemarks>
             </li>
 
          </ul> 
      </div>
      <div class="swiper-container auto_ctl_content_box">
          <div class="swiper-wrapper">
            <div class="swiper-slide slide_dome">
              <!-- v-if="isAddPlan" -->
              <tabs @change="tabsChange" :taskList="planTaskList" type="planInfo" :infos="fromInfo"></tabs>
              <div class="swiper-container auto_plan_ctl_box">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide swiper-no-swiping">
                      <timeFrom :devInfo='info' @update="reqIotActuatorControlDetail(false)" :planInfo="fromInfo.planInfo1"></timeFrom>
                    </div>
                    <div class="swiper-slide swiper-no-swiping">
                      <timeFrom :devInfo='info' @update="reqIotActuatorControlDetail(false)" :planInfo="fromInfo.planInfo2"></timeFrom>
                    </div>
                  </div>
              </div>
            </div>
            <div class="swiper-slide slide_dome">
          
              <tabs @change="tabsLinkageChange" :taskList="linkageTaskList" type="linkageInfo" :infos="fromInfo"></tabs>
              <div class="swiper-container auto_linkage_ctl_box">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide swiper-no-swiping">
                      <linkageFrom :devInfo='info' @update="reqIotActuatorControlDetail(false)" :linkageInfo="fromInfo.linkageInfo1"></linkageFrom>
                      
                    </div>
                    <div class="swiper-slide swiper-no-swiping">
                        <linkageFrom :devInfo='info' @update="reqIotActuatorControlDetail(false)" :linkageInfo="fromInfo.linkageInfo2"></linkageFrom>
                    </div>
                  </div>
              </div>

            </div>
          </div>
      </div>
      
  </div>
</template>

<script>
  import timeFrom from './timeFrom.vue'
  import linkageFrom from './linkageFrom.vue'
  import tabs from './tabs.vue'
  export default {
      components:{
          timeFrom,
          linkageFrom,
          tabs
      },
      props:{
         info:{
             type: Object,
             default: () => {
                 return {}
             }
         }
      },
      data(){
          return{
              activeName: 'first',
              navList:[
                  {
                      name:'定时控制',
                      code: 0
                  },
                  {
                      name: '联动控制',
                      code: 1
                  }
              ],
              fromInfo:{
                linkageInfo1: null,
                planInfo1: null,
                linkageInfo2: null,
                planInfo2: null,
              },
              tabActive: 0,
              tabsCode: 1,
              autoCtlSwiper: null,
              planCtlSwiper: null,
              linkageCtlSwiper: null,
              isAddPlan: false,
              isAddLinkage: false,
              planTaskList: [{
                name: '定时任务1',
                code: 0
              },
              {
                name: '定时任务2',
                code: 1
              },],
              linkageTaskList: [
                {
                  name: '联动任务1',
                  code: 0
                },
                {
                  name: '联动任务2',
                  code: 1
                }
              ]
          }
      },
      created(){
         this.reqIotActuatorControlDetail()

          
      },
      computed: {
        //  fromKey(){
        //     const startKey = this.tabActive == 0 ? 'planInfo' : 'linkageInfo' 
        //     const endKey =  this.tabsCode == 1 ? '1' : '2'
        //     return startKey + endKey
        //  },

      },
      methods:{
         toAddPlan(){
           this.isAddPlan = true
         },
         toAddLinkage(){
           this.isAddLinkage = true
         },
         tabsChange(code){
            // this.tabsCode = code
            this.planCtlSwiper.slideTo(code,500,false);
         },
         tabsLinkageChange(code){
            this.linkageCtlSwiper.slideTo(code,500,false)
         },
         swiperInfo(dome) {
               const _that = this 
               let autoCtlSwiper = new this.$Swiper(dome,{
                    // autoHeight: true,
                    on: {
                        slideChangeTransitionEnd: function(){
                          _that.tabActive= this.activeIndex
                        },
                    }
               })  
               this.autoCtlSwiper = autoCtlSwiper


          },
          navChange(code){
              this.tabActive = code
              if(this.autoCtlSwiper) {
                 this.autoCtlSwiper.slideTo(code,500,false);
             }
          },
          async reqIotActuatorControlDetail(isNewSwiper= true){
               const res = await this.$api.IotActuatorControlDetail({id: this.info ?.id })
               
               if(res){
                   let { planDetail, linkageDetail } = res
                   if(!!!planDetail && !!linkageDetail){
                       this.tabActive = 1
                   }
                  if(linkageDetail && linkageDetail.length){
                    linkageDetail.map((item,i) => this.fromInfo['linkageInfo' + (i+1)] = item) 
                    this.isAddLinkage = true
                  }
                  if(planDetail && planDetail.length) {
                    planDetail.map((item,i) => this.fromInfo['planInfo' + (i+1)] = item)
                    this.isAddPlan = true
                  }
                  if(!isNewSwiper) return
                  this.$nextTick(()=> {
                    let that = this
                    this.swiperInfo('.auto_ctl_content_box')
                    this.planCtlSwiper = new this.$Swiper('.auto_plan_ctl_box') 
                    this.linkageCtlSwiper = new this.$Swiper('.auto_linkage_ctl_box')
                    
                  })
                 

               }
          }
      }
  }
</script>

<style lang="scss" scoped>
.autoCtl_box{
   width: 100%;
   padding: 0 10px;
   box-sizing: border-box;
   color: #fff;
   .tabs_header_box{
       width: 100%;
       height: 44px;
       & > ul{
           width: 100%;
           height: 100%;
           line-height: 44px;
           text-align: center;
           border-bottom: 2px solid #202E37;
           font-size: 16px;
           display: flex;
           & > li:not(:first-child) {
               margin-left: 25px;
           }
       }
   }
  //  .swiper-slide{
  //   box-sizing: border-box;
  //      padding: 20px 0px 10px 0px;
  //  }
  .auto_ctl_content_box{
        box-sizing: border-box;
       padding: 20px 0px 10px 0px;
  }
}
.swiper-slide{height:1px} /* 随意指定一个height值即可 */    
.swiper-slide-active { height:auto}
</style>