<template>
  <div>
  
  <el-popover
      class="ctl_popover"
      placement="bottom"
      @show="toShow"
      @hide="toHide"
      width="230"
      trigger="click">
      <div>
        <template  v-for="item of list"> 
        <div class="batch_list" v-if="item.funs && item.funs.length"  :key="item.id">
            <span class="flex-shorke">{{ item.name }}：</span>
            <div class="batch_param_box">
              <div v-for="subItem of item.funs" :key="subItem.id" @click="toCtl(item,subItem)" class="subItem_box">
                {{ subItem.name }}
              </div>
            </div>
        </div>
      </template>
      
      </div>
      <div class="batch_but" slot="reference">
        <span>批量控制</span>
        <span :class="iconName"></span>
      </div>
  </el-popover>

  </div>
</template>

<script>
  export default {
    props:{
     devId: String
    },
    data(){
       return{
         iconName: 'el-icon-caret-bottom',
         list: []
       }
    },
    watch:{
     devId:{
       handler(val){
         if(val) this.reqList()
       },
       immediate: true
     }
    },
    created(){
       
    },
    methods:{
      toShow(){
        this.iconName = 'el-icon-caret-top'
      },
      toHide(){
         this.iconName = 'el-icon-caret-bottom' 
      },
      toCtl(item,subItem){
        this.$confirm(`您正在批量控制${item.name}，执行动作${subItem.name},确认继续？`, "提示")
        .then(() => {
           this.$emit('ctl',{parentInfo: item,childInfo: subItem})
        })
      },
      async reqList(){
         const res = await this.$api.IotExecutorTypeTreeList({id: this.devId})
  
         this.list = res || []
      }
    }
  }
</script>

<style lang="scss" scoped>
.batch_but{
   font-size: 12px;
   border-radius: 3px;
   padding: 9px 15px;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #2f5259;
   border:1px solid #1f776d;
   line-height: 1;
   white-space: nowrap;
   cursor: pointer;
   & > span:first-child{
     margin-right: 3px;
   }
   
}
.batch_list:not(:last-child){
  // border-bottom: 1px solid #fff;
  margin-bottom: 10px;
  // padding-bottom: 5px;
}
.batch_list{
   display: flex;
   font-size: 12px;
   
  //  align-items: center;
   .flex-shorke{
    flex-shrink: 0;
    // margin-top: -3px;
    width: 60px;
    height: 22px;
    line-height: 22px;
    text-align: right;
   }
   .batch_param_box{
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      .subItem_box{
        width: 80px;
        height: 22px;
        line-height: 22px;
        text-align: center;
        margin-bottom: 5px;
        background-color: #2c414e;
        border-radius: 5px;

      }
      .subItem_box:nth-child(2n + 1){
         margin-right: 5px;
      }
      .subItem_box:hover{
        background-color: #00C497;
        cursor: pointer;
      }
   }
}
</style>