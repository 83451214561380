import { render, staticRenderFns } from "./warnBoard.vue?vue&type=template&id=4e4680ea&scoped=true&"
import script from "./warnBoard.vue?vue&type=script&lang=js&"
export * from "./warnBoard.vue?vue&type=script&lang=js&"
import style0 from "./warnBoard.vue?vue&type=style&index=0&id=4e4680ea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e4680ea",
  null
  
)

export default component.exports