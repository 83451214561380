<template>
  <div class="task_info_box borderBase boxBg cp" @click="toAdd">
     <div class="task_header_box">
        <span class="baseColor font-16">{{ title }}</span>
        <div class="d-flex align-items-center">
        </div>
     </div>
     <div class="task_content_box">
        <img class="icon_box" :src="iconImg"/>
        <div class="line_box"></div>
        <div class="info_box add_style">
           <span class="el-icon-plus"></span>
        </div>
     </div>
  </div>
</template>

<script>
  export default {
    props:{
      isJoint:{
        type: Boolean,
        default: false
      }
    },
    data(){
      return {
        //  iconImg: require('@/assets/imgs/control/defalut/time.png')
      }
    },
    computed:{
       iconImg(){
          return this.isJoint ? 
          require('@/assets/imgs/control/defalut/linkage.png') :
          require('@/assets/imgs/control/defalut/time.png')
       },
       title(){
        return this.isJoint ? '联控编号2' : '定时编号2'
       }
    },
    methods:{
      toAdd(){ this.$emit('click')}
    }
  }
</script>

<style lang="scss" scoped>
.task_info_box{
  width: 370px;
  height: 340px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  .task_header_box{
     height: 42px;
     padding: 0 20px;
     box-sizing: border-box;
     display: flex;
     align-items: center;
     justify-content: space-between;
     border-bottom: 1px solid #15232D;
  }
  .task_content_box{
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon_box{
      width: 64px;
      height: auto;
      margin: 20px 0;
    }
    .line_box{
      width: 100%;
      height: 2px;
      background-image: linear-gradient(to right, #28353F,#00D1A1,#17BFD4,#28353F)
    }
    .info_box{
       flex: 1;
       min-height: 0;
       margin: 20px 0;
    } 
    .add_style{
       display: flex;
       align-items: center;
       justify-content: center;
       font-size: 40px;
       color: #999;
    }
  }
  .c999{
    color: #999;
  }
}
</style>