import {  mapTo,getParentChildInfo } from '@/libs/util'
export default {
   data(){
    return{
      isAddDtos: true,
    }
   },
   computed:{
      parentChildInfo(){
        return targetId => {
          let infos = getParentChildInfo(this.devSensorList,targetId,{childKey: 'sensors',value: 'id' })
          return infos
        }
      },
      devId(){
        if(this.ctlForm.linkageWay == 1 && this.ctlForm.iotControlLinkageConditions.length){
          let targetId = this.ctlForm.iotControlLinkageConditions[0].sensorId
          let infos = this.parentChildInfo(targetId)
          return infos.parentInfo.devId
        }
        return ''
      },
      devSensorList(){ 
          return this.$store.state.devSensorList || []   
      },
      dtosText(){
        return item => {
            let { parentInfo, childrenInfo } = item.showData
            return parentInfo.label + '/' + childrenInfo.label
        }
      },
      tremValue(){
        return item => {

          return item.value + item.showData.childrenInfo.unit
        }
      }
   },
   methods:{
    dtosColse(item){
      if(item){
         item.isEdit = false 
      }else{
        if(this.ctlForm.iotControlLinkageConditions.length) this.isAddDtos = false
      }
    },
    dtosAddSubmit(data){

       this.ctlForm.iotControlLinkageConditions.push(data)
       this.isAddDtos = false
       this.$emit('updateHeight')
    }, 
    dtosEditSubmit(data,item){
      
     
      setTimeout(()=>{
          this.$msg.success('编辑成功')
          mapTo(data,item)
          item.isEdit = false
      },1000)
    },
    toAddDtos(){

      if(this.isAddDtos) return

      this.ctlForm.iotControlLinkageConditions.map(item => {
        
          if(item.isEdit) item.isEdit = false
      })
       this.isAddDtos = true
      
    },
    toEditDtos(item){
       this.isAddDtos = false
       item.isEdit = true
    },
    toDelDtos(i){
      
      this.$confirm(`您正在删除执行器联动条件，确认继续？`, "提示").then(()=> {
        this.ctlForm.iotControlLinkageConditions.splice(i,1)
      })
        
    },
   }
}