<template>
  <!-- 轨道 -->
<div class="float_anime_box">
  <div v-for="(item,i) in list" :key="i" :style="item.styleVar">{{ item.name }}</div>
</div>

</template>

<script>
  export default {
     data(){
        return {
          activeCircle: 1,
          list:[]
            
        }
     },
     created(){
      //  this.createAnime()
     },
     methods:{
       createAnime(){
          for (let i = 0; i < 100; i++) {
            // const el = document.createElement("div");

            // el.style.cssText = ;

            let infos = {
               name: i % 2 === 0 ? "虎收到货后家是的收到货" : "兔收到货后",
              //  duration: `--duration:` + (Math.random() * 50 + 10),
              //  x: `--x:` + (Math.random() * 50 + 100) + 'px',
               styleVar:{
                   '--duration':(Math.random() * 40 + 0),
                   '--x':parseInt(Math.random()*(400-10+1)+10,10) + 'px'
               }

            }
            this.list.push(infos)
          }
       }
     }
  }
</script>

<style lang="scss" scoped>
.float_anime_box{
   width: 600px;
   height: 500px;
   margin: 100px auto 0 auto;
   overflow: hidden;
   position: relative;
   & > div{
      max-width: 200px;
      height: 22px;
      position: absolute;
      left: var(--x);
      top: 0;
      border-radius: 5px;
      padding: 2px 5px;
      margin: 0 5px;
      display: inline-block;
      border:1px solid #4da6e5;
      // box-shadow: 0 0 10px 2px #79bcec, 0 0 10px 2px #a6d2f2;
      animation: hd 8s linear infinite;
      animation-duration: calc(300s / var(--duration));
      color: white;
      text-align: center;
      line-height: 22px;
      font-size: 12px;

   } 
   & > div:nth-of-type(even) {
        border:1px solid #df208f;
        // box-shadow: 0 0 10px 2px #79bcec, 0 0 10px 2px #a6d2f2;
    }
    /* 定义动画 */
    @keyframes hd {
      from {
        transform: translateY(700px) scale(0.5);
      }
      to {
        transform: translateY(-100px) scale(1.2);
      }
    }
}


</style>