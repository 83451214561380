import { nanoid } from 'nanoid'
export default{
  
  computed:{
    client() { return this.$store.getters.stompClient }
  },
  methods:{
    
    // 开始订阅
    startTopic(){
      
      // this.activeRowData.isLoading= true
      const sourceTopic = "/topic/devicecaskcmdImpIementcstatus." + this.nanoid
      this.activeTopic = this.client.subscribe(sourceTopic, this.sourceCallback);
    },
    // 暂停订阅
    pauseTopic(){
        this.activeTopic ?.unsubscribe()
    },
    // 订阅回调
    sourceCallback(frame){
      const ObjFrame = JSON.parse(frame.body)
  
      if(ObjFrame.status !== 0){

        this.isActuatorSuccess(ObjFrame.id,false) 
        // this.isActuatorSuccess(ObjFrame.id)  
      }else this.hanldeOldDevInfo(ObjFrame.id) 
    },
    hanldeOldDevInfo(id,isSuccess = true){
 
      this.draggableList.map(item => {
         if(item.protocolVersion <= 0 && item.id == id){
            this.delCheck(id)
            if(isSuccess){

              item.ctlStatus = 'success'
              setTimeout(() => {
                // item.channelStatus = item.controlAction
                // item.actuatorOpenPercent = item.openPercent
                if(this.isColse){
                  if(item.isReversible){
                    item.channelStatus = 2
                    item.actuatorOpenPercent = 0
                  }else{
                    item.channelStatus = 0
                    item.actuatorOpenPercent = 0 
                  }
                }else{
                  item.channelStatus = item.controlAction
                  item.actuatorOpenPercent = item.openPercent   
                }
                item.isCtl = false
                item.ctlStatus = ''
              }, 1000);
            }else{
              item.ctlStatus = 'error'
            }
         }
      })

    },
    // 根据id判断是否是老设备，并结束动画
    // 判断是否全部接收到了消息
    isSuccessAllGetMsg(){

    },
    // 批量控制
    async taskAllTaskCtl(){
      if(!this.checkInfo || !this.checkInfo.length) return this.$msg.info('请选择执行器')
      this.isColse = false
      const data = {
        taskCmdId: this.nanoid,
        actuatorIds: this.checkInfo
     } 
      this.startTopic() // 订阅设备
      this.isCtlAnimal() // 开启动画
      this.reqAllCtl(data)
    },
    // 批量关闭
    async taskAllColseTaskCtl(){
      if(!this.checkInfo || !this.checkInfo.length) return this.$msg.info('请选择执行器')
      this.isColse = true
      this.nanoid = nanoid()  
      const data = {
        taskCmdId: this.nanoid,
        actuatorIds: this.checkInfo
     } 
      this.startTopic() // 订阅设备
      this.isCtlAnimal() // 开启动画
      this.reqAllColseCtl(data)
    },
    async reqAllCtl(data){
      await this.$api.IotTaskCmdControl(data)
    },
    async reqAllColseCtl(data){
      await this.$api.IotTaskCmdReversalControl(data)
    },
    // 删除指定执行器动画
    cancelLoading(id){
      this.isCtlAnimal(false,id)
    },
    // 重新请求控制
    testRest(id){
      const data = {
        taskCmdId: this.nanoid,
        actuatorIds: [id]
     } 
     this.checkInfo.push(id)
     this.isCtlAnimal(true,id) // 开启动画
     this.reqAllCtl(data)
    },
    // 开启动画
    isCtlAnimal(loading = true,id){
        this.draggableList.map(item =>{
           if(id && item.id == id){
             item.isCtl = loading
             item.ctlStatus = ''
             return
           }
           if(this.checkInfo.includes(item.id)){
              item.isCtl = loading
              item.ctlStatus = ''
           }
        })
    },
    // 指定执行器执行是否成功
    isActuatorSuccess(id,isSuccess = true){
      this.draggableList.map(item =>{
        if(item.id == id){
            this.delCheck(id)
 
            if(isSuccess){
              item.ctlStatus = 'success'
              setTimeout(() => {
                if(this.isColse){
                   if(item.isReversible){
                     item.channelStatus = 2
                     item.actuatorOpenPercent = 0
                   }else{
                     item.channelStatus = 0
                     item.actuatorOpenPercent = 0 
                   }
                }else{
                  item.channelStatus = item.controlAction
                  item.actuatorOpenPercent = item.openPercent   
                }
                item.isCtl = false
                item.ctlStatus = ''
              }, 1000);
            }else{
              item.ctlStatus = 'error'
            }
        }
     })
    }
  }
}