<template>
   <div class="loading_main_box" v-if="isShow">
     <div class="loading_content">
        <p class="font-14 mb-10" v-if="text">{{ text }}</p>
       <template v-if="!status">
        <div style="width: 40px;height: 40px;">
          <svg version="1.1" id="L2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
              <circle fill="none" stroke="#00c395" stroke-width="6" stroke-miterlimit="10" cx="50" cy="50" r="48"/>
              <line fill="none" stroke-linecap="round" stroke="#00c395" stroke-width="6" stroke-miterlimit="10" x1="50" y1="50" x2="85" y2="50.5">
                <animateTransform 
                    attributeName="transform" 
                    dur="2s"
                    type="rotate"
                    from="0 50 50"
                    to="360 50 50"
                    repeatCount="indefinite" />
              </line>
              <line fill="none" stroke-linecap="round" stroke="#00c395" stroke-width="6" stroke-miterlimit="10" x1="50" y1="50" x2="49.5" y2="74">
                <animateTransform 
                    attributeName="transform" 
                    dur="15s"
                    type="rotate"
                    from="0 50 50"
                    to="360 50 50"
                    repeatCount="indefinite" />
              </line>
          </svg>
        </div>        
        <div v-if="!status" class="mt-10 font-14 baseColor d-flex align-items-center">
          <span>执行中</span>
          <div class="dot_box">
            <div class="dot1"> </div>
            <div class="dot2"></div>
            <div class="dot3"></div>
          </div>
        </div>
      </template>
      <template v-else-if="status == 'success'">
        <span class="el-icon-circle-check font-40 baseColor"></span>
        <p class="font-14 mt-10 baseColor">完成执行</p>
      </template>
      <template v-else>
        <span class="el-icon-circle-close font-40 errorColor"></span>
        <p class="font-14 mt-10 errorColor">执行失败,点击重试</p>
        <div class="mt-10">
          <el-button size="mini" plain @click.stop="toCancel">取消</el-button>
          <el-button type="primary" size="mini"  plain @click.stop="toRest">重试</el-button>
        </div>
        
      </template>
     </div>
  </div>
</template>

<script>

  export default {
    props:{
      
       isShow:{
         type: Boolean,
         default: false
       },
       status: String,
       text:{
         type: String
       }
     },
     methods:{
      toCancel(){
         this.$emit('cancel')
      },
      toRest(){
         this.$emit('rest')
      }
     }
  }
</script>

<style lang="scss" scoped>
.loading_main_box{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 18px;
  background-color: rgba(rgb(0, 0, 0),0.5);
  .errorColor{
     color: #ff6470;
  }
  .mt-6{
    margin-top: 6px;
  }
  .loading_content{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .dot_box{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .dot1, .dot2, .dot3 {
        background: #fff;
        width: 4px;
        height: 4px;
        // border:double;
        border-color:black;
        border-radius: 50%;
        margin: 4px;
        background-color: var(--el-color-primary);
    }

    .dot1 {
        animation: jump 1.6s -0.32s linear infinite;
        // background: #4B0082;
    }
    .dot2 {
        animation: jump 1.6s -0.16s linear infinite;
        // background: #B22222;
    }
    .dot3 {
        animation: jump 1.6s linear infinite;
        // background: #006400;
    }

    @keyframes jump {
        0%, 80%, 100% {
            -webkit-transform: scale(0);
            transform: scale(0);
        } 40% {
              -webkit-transform: scale(1.2);
              transform: scale(1.2);
          }
    }
  }
}
</style>