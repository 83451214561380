<template>
  <div class="loading_main_box" v-if="isShow">
     <div class="loading_content">
        <div style="width: 40px;height: 40px;" v-if="isAnimale">
          <svg version="1.1" id="L2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
              <circle fill="none" stroke="#fff" stroke-width="6" stroke-miterlimit="10" cx="50" cy="50" r="48"/>
              <line fill="none" stroke-linecap="round" stroke="#fff" stroke-width="6" stroke-miterlimit="10" x1="50" y1="50" x2="85" y2="50.5">
                <animateTransform 
                    attributeName="transform" 
                    dur="2s"
                    type="rotate"
                    from="0 50 50"
                    to="360 50 50"
                    repeatCount="indefinite" />
              </line>
              <line fill="none" stroke-linecap="round" stroke="#fff" stroke-width="6" stroke-miterlimit="10" x1="50" y1="50" x2="49.5" y2="74">
                <animateTransform 
                    attributeName="transform" 
                    dur="15s"
                    type="rotate"
                    from="0 50 50"
                    to="360 50 50"
                    repeatCount="indefinite" />
              </line>
          </svg>
        </div>
        <p v-if="text" class="mt-10 textStyle">{{ text }}</p>
        <div class="mt-10 d-flex justify-content-center">
          <slot></slot>
        </div>
    </div>
  </div>
  
</template>

<script>
  export default {
     props:{
       text:{
          type: String
       },
       isAnimale:{
           type: Boolean,
           default: true
       },
       isShow:{
         type: Boolean,
         default: false
       }
     }
  }
</script>

<style lang="scss" scoped>
.loading_main_box{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 18px;
  background-color: rgba(#000,0.4);
  .loading_content{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .textStyle{
     min-width: 200px;
     text-align: center;
  }
  
}
</style>