<template>
    <ul class="week_box">
        <li 
        v-for="item of weekDays"
        :key="item.label"
        :class="[activeWeek.includes(item.value)? 'baseColor ctr_box_border': '','subItem', 'cp']"
        @click="weeksCheck(item)"
        >{{ item.label }}</li>
        <li :class="['all_box', 'cp',isAllCheck?'baseColor':'']" @click="allClick">全选</li>
    </ul>
</template>

<script>
    export default {
        props:{
           value: {
               type:Array,
               default: ()=> []
           }
        },
        data(){
            return {
                weekDays:[
                    {
                        value: '1',
                        label: '一'
                    },
                    {
                        value: '2',
                        label: '二'
                    },
                    {
                        value: '3',
                        label: '三'
                    },
                    {
                        value: '4',
                        label: '四'
                    },
                    {
                        value: '5',
                        label: '五'
                    },
                    {
                        value: '6',
                        label: '六'
                    },
                    {
                        value: '7',
                        label: '日'
                    },
                ],
                activeWeek: [],
                weeksText:'',
            }
        },
        created(){
           this.activeWeek = this.value
        },
        watch:{
           value(val){
           
             this.activeWeek = val
           },
           activeWeek(val){
               this.$emit('input',val)
           }
        },
        computed:{
            isAllCheck(){
               return this.activeWeek.length == this.weekDays.length ? true : false
            }
        },
        methods:{
            weeksCheck(item){
                const index = this.activeWeek.indexOf(item.value)
                if(index == -1){
                    this.activeWeek.push(item.value)
                }else{
                    this.activeWeek.splice(index,1)
                }
                this.weeksText = this.activeWeek.join(',')    
                
            },
            allClick(){
                if(this.isAllCheck){
                   this.activeWeek = []
                }else{
                   let arrList = []
                   this.weekDays.forEach(item => {
                     arrList.push(item.value)
                   }) 
                   this.activeWeek = arrList
                }
                
            }
        }
    }
</script>

<style lang="scss" scoped>
  .week_box{
      width: 100%;
       display: flex;
       align-items: center;
       .subItem {
            width: 28px;
            height: 28px;
            border-radius: 14px;
            line-height: 28px;
            text-align: center;
            border: 1px solid #fff;
            color: #fff;
            font-size: 14px;
            margin-right: 15px;

        }
        .all_box{
            color: #fff;
        }
  }
</style>