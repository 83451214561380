<template>
  <div class="task_info_box borderBase boxBg">
     <div class="task_header_box">
        <span class="baseColor font-16">联控编号{{ index + 1}}</span>
        <div class="d-flex align-items-center">
          <!-- <fromItem :isEdit="true" :taskId="taskId" :infos="infos"></fromItem> -->
          <span class="el-icon-edit c999 cp" @click="toEdit"></span>
          <span class="c999 el-icon-delete ml-6 cp" @click="delRow"></span>
          
        </div>
     </div>
     <div class="task_content_box">
        <img class="icon_box" :src="iconImg"/>
        <div class="line_box"></div>
        <div class="info_box">
       
          
          <div class="row_box" v-for="(item,i) in conditionList" :key="item.id">
            <span class="label_box">联动条件{{ (i+1) }}：</span>
            <span class="baseColor">{{ hanlderText(item) }}</span>
          </div>
          
          <div class="row_box">
            <span class="label_box">是否启用：</span> 
            <div>
                  <el-switch
                  v-model="status"
                  :active-value="1"
                  :inactive-value="2"
                  @change="switchChange"
                  active-color="#00C395"
                  inactive-color="#343F48">
                  </el-switch>
                  <span :class="['switch_text',status == 1 ? 'baseColor':'']">{{ statusText }}</span>
              </div>
          </div>
        </div>
     </div>
  </div>
</template>

<script>
  import fromItem from './fromItem.vue'
  import bus from '@/libs/eventBus.js'
  export default {
    components: { fromItem },
    props:{
      infos:Object,
      index:{
        type: Number,
        default: 0
      },
      taskId: String
    },
    data(){
      return {
         iconImg: require('@/assets/imgs/control/defalut/linkage.png'),
         status: 1
      }
    },
    created(){
  
      this.status = this.infos ?.status || 1
    },
    computed:{
       conditionList(){
         return this.infos ?.iotLinkageConditionModels || []
       },
       hanlderText(){
          return item => {
            let compareText =  item.compareType == 2 ? '小于' : item.compareType == 4 ? '大于' : '' 
          
            let value = item.value + item.enUnit
            return item.sensorName + compareText + value
          }
       },
       statusText(){
          return this.status == 1 ? '启用':'停用'
       }
    },
    methods:{
      toEdit(){
         this.showDialog(fromItem,{
          props:{
            infos: this.infos,
            taskId: this.taskId,
            isEdit: true
          }
         },{
          width:'520px',
          title: '编辑联动控制',
        },()=> {
            
        })
      },
      switchChange(){
       
        this.$confirm(`您正在${this.statusText}联动条件，确认继续？`, "提示").then(()=> {
           this.$api.IotTaskLinkageStatus({id: this.infos.id}).then(res => {
               if(res){
                 this.$msg.success('设置成功')
                 bus.$emit('updateData','joint')
               }else this.status = this.status == 1 ? 2 : 1
           })
        }).catch(()=> {
              this.status = this.status == 1 ? 2 : 1
        })
      },
      delRow(){
        this.$confirm(`您正在删除联动任务，确认继续？`, "提示").then(()=> {
           this.$api.DelIotTaskLinkage({id: this.infos.id}).then(res => {
               if(res){
                 bus.$emit('updateData','joint')
                 this.$msg.success('删除成功')
               }
           })
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.task_info_box{
  width: 370px;
  height: 340px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  .task_header_box{
     height: 42px;
     padding: 0 20px;
     box-sizing: border-box;
     display: flex;
     align-items: center;
     justify-content: space-between;
     border-bottom: 1px solid #15232D;
  }
  .task_content_box{
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon_box{
      width: 64px;
      height: auto;
      margin: 20px 0;
    }
    .line_box{
      width: 100%;
      height: 2px;
      background-image: linear-gradient(to right, #28353F,#00D1A1,#17BFD4,#28353F)
    }
    .info_box{
       flex: 1;
       min-height: 0;
       margin: 15px 0;
       .row_box{
          display: flex;
          font-size: 14px;
          .label_box{
             width: 80px;
             text-align: right;
          }
       }
       .row_box:not(:first-child){
          margin-top: 8px;
       }
    } 
   
  }
  .c999{
    color: #999;
  }
  .switch_text{
   margin-left: 12px;
   color: #999;
 }
 .ml-6{
   margin-left: 6px;
 }
}
</style>