<template>
  <div class="timeFrom_box mt-20">
        
        <div class="from_box linkageFrom">
            <el-form :model="ctlForm" :rules="rules" ref="ctlForm" size="small" label-width="110px" class="demo-ruleForm">
              <el-form-item label="有效时间段" prop='timeSlot'>
          
                  <div class="termTime_box">
                    <el-time-picker
                          v-model="ctlForm.timeSlot"
                          value-format="HH:mm"
                          format="HH:mm"
                          style="width:100%"
                          range-separator="至"
                          start-placeholder="开始时间"
                          end-placeholder="结束时间"
                          placeholder="选择时间范围"
                          is-range
                          @change="timeChange"
                          :clearable="false"
                      >
                      </el-time-picker>
                    <el-button :type="butStutas" @click="allDay" size="mini" class="allDay_but" plain icon="el-icon-check">全天</el-button>
                  </div>
                  
              </el-form-item>
              <el-form-item label="执行条件">
                <div class="d-flex align-items-center">
                  <el-radio-group v-model="ctlForm.linkageWay" @change="linkageWayChange">
                      <el-radio :label="item.value" v-for="item of $dictionary('linkageWay')" :key="item.value">{{ item.label }}</el-radio>
                  </el-radio-group>
                  <HnRemarks class="remarks_box" :content='remarksContent'></HnRemarks>
              </div>
            </el-form-item>
             
            <template v-if="isHasData">
              <el-form-item  :label="'联动条件'+(i + 1)" v-for="(item,i) of ctlForm.addIotLinkageConditionDtos" :key="i">
                <div class="joint_from_box">
                <div class="d-flex">
                  <span class="sinleline dev_info_text">{{ dtosText(item)}}</span>
                  <span class="ml-6 flex-shrink-0">{{ tremFormatter(item.compareType) }}</span>
                  <span class="ml-6 flex-shrink-0">{{ tremValue(item) }}</span>
                </div>
                <div>
                  <span @click="toEditDtos(item)" class="el-icon-edit cp"></span>
                  <span @click="toDelDtos(i)" class="el-icon-delete ml-6 cp "></span>
                  <span @click="toAddDtos" class="el-icon-plus ml-6 cp " v-if="ctlForm.addIotLinkageConditionDtos.length == (i+1)"></span>
                </div>
              </div>
              <setDtos @submit="data => dtosEditSubmit(data,item)" @close='dtosColse(item)' :infos='item' :isEdit="true" v-if="item.isEdit"></setDtos>
              </el-form-item> 
            </template>
            <template v-if="isAddDtos">
              <el-form-item label="新增联动条件" prop="addIotLinkageConditionDtos">
                 <setDtos @submit="dtosAddSubmit" :devId="devId" @close='dtosColse'></setDtos>
              </el-form-item> 
            </template>
            <el-form-item label="状态">
                    <el-switch
                    v-model="ctlForm.status"
                    :active-value="1"
                    :inactive-value="2"
                    active-color="#00C395"
                    inactive-color="#343F48">
                    </el-switch>
                    <span :class="['switch_text',ctlForm.status == 1 ? 'baseColor':'']">{{ ctlForm.status == 1 ? '启用':'停用' }}</span>
            </el-form-item>
            </el-form> 
        </div>
        <!-- <HnSyncLoading :loading="perLoading" :erroStatus='erroStatus' width='80' types="line"/> -->
      
  </div>
</template>

<script>

  import { deepClone,mapTo,getParentChildInfo } from '@/libs/util'
  import setDtos from './setDtos.vue'
  import bus from '@/libs/eventBus.js'
  export default {
    components: {
      setDtos
    },
    props: {
      taskId: String,
      infos: Object,
      isEdit:{
        type: Boolean,
        default: false
      }
    },
    data(){
      const arrayEmpty = (rule, value, callback) => {
        if (!Array.isArray(value)) callback(new Error('数据格式为数组'))
        if (value.length) {
          callback()
        } else {
          callback(new Error('请添加联动条件'))
        }
      }
      return {
          ctlForm: {
              id:'',
              beginTime: '',
              endTime: '',
              timeSlot: ['00:00','23:59'],
              taskId: '',
              linkageWay: 0,
              addIotLinkageConditionDtos: [],
              status: 1
          },
          butStutas: 'primary',
          // erroStatus: false,
          // perLoading: false,
          // checkTime: '3',
          rules: {
            addIotLinkageConditionDtos: [
              { validator: arrayEmpty, trigger: 'blur', required: true}
            ]
          },
          // ctlTimeType: '3',
          isAddDtos: true,
          remarksContent: '选择满足所有条件时，需要选择同一设备的执行器'
      }
    },
    created(){
      if(this.isEdit) this.analysisData()

    },
    computed:{
      tremFormatter(){
          return val => {
            return val == 2 ? '小于' : val == 4 ? '大于' : ''
          }
      },
      isHasData(){
        return this.ctlForm.addIotLinkageConditionDtos && this.ctlForm.addIotLinkageConditionDtos.length
      },
      parentChildInfo(){
        return targetId => {
          let infos = getParentChildInfo(this.devSensorList,targetId,{childKey: 'sensors',value: 'id' })
          return infos
        }
      },
      devId(){
         if(this.ctlForm.linkageWay == 1 && this.ctlForm.addIotLinkageConditionDtos.length){
          let targetId = this.ctlForm.addIotLinkageConditionDtos[0].sensorId
          let infos = this.parentChildInfo(targetId)
          return infos.parentInfo.devId
         }
         return ''
      },
      devSensorList(){ 
          return this.$store.state.devSensorList || []   
      },
      dtosText(){
         return item => {
            let { parentInfo, childrenInfo } = item.showData
            return parentInfo.label + '/' + childrenInfo.label 
         }
      },
      tremValue(){
        return item => {
  
           return item.value + item.showData.childrenInfo.unit
         }
      }
    },
    submit (dialog, callback) {
      this.$refs.ctlForm.validate( async(valid) => {
        if(valid){
             let cloneData = deepClone(this.ctlForm)
             cloneData.taskId = this.taskId
             // 处理时间段
             if(cloneData.timeSlot && cloneData.timeSlot.length){
                 cloneData.beginTime= cloneData.timeSlot[0]
                 cloneData.endTime= cloneData.timeSlot[1]
              }
              cloneData.addIotLinkageConditionDtos = cloneData.addIotLinkageConditionDtos.map(item =>({
                compareType: item.compareType,
                value: item.value,
                sensorId: item.sensorId
              }))
              const api = this.isEdit ? 'EditIotTaskLinkage' : 'AddIotTaskLinkage' 
              const res = await this.$api[api](cloneData)
              if(res){
                bus.$emit('updateData','joint')
                dialog.okLoading = false;
                dialog.close();
                callback();
                setTimeout(() => {
                  this.$msg.success('设置成功')
                }, 500);
              }
              
           } 
      });
   
   },
    methods:{
     
      allDay(){
          this.$set(this.ctlForm,'timeSlot',['00:00','23:59'])
          this.butStutas = 'primary'
      },
      dtosColse(item){
        if(item){
           item.isEdit = false 
        }else{
          if(this.ctlForm.addIotLinkageConditionDtos.length) this.isAddDtos = false
        }
      },
     
      dtosAddSubmit(data){

         this.ctlForm.addIotLinkageConditionDtos.push(data)
         this.isAddDtos = false
      }, 
      dtosEditSubmit(data,item){
        
       
        setTimeout(()=>{
            this.$msg.success('编辑成功')
            mapTo(data,item)
            item.isEdit = false
        },1000)
      },
      toAddDtos(){

        if(this.isAddDtos) return

        this.ctlForm.addIotLinkageConditionDtos.map(item => {
          
            if(item.isEdit) item.isEdit = false
        })
         this.isAddDtos = true
        
      },
      toEditDtos(item){
         this.isAddDtos = false
         item.isEdit = true
      },
      toDelDtos(i){
        
        this.$confirm(`您正在删除执行器联动条件，确认继续？`, "提示").then(()=> {
          this.ctlForm.addIotLinkageConditionDtos.splice(i,1)
        })
          
      },
      linkageWayChange(){
        if(this.ctlForm.linkageWay == 1 && this.isHasData){
          this.$confirm(`选择满足所有条件时，需要选择同一设备中的执行器，如果联动条件不匹配，会被清除数据，确认继续？`, "提示").then(()=> {
            this.ctlForm.addIotLinkageConditionDtos = []
            this.isAddDtos = true
          }).catch(() => {
              this.ctlForm.linkageWay = 0
          })
        }
        
      },
      analysisData(){
        Object.entries(this.ctlForm).map(([key,val])=>{
             if(key == 'addIotLinkageConditionDtos'){
                let list = this.infos.iotLinkageConditionModels
                this.ctlForm[key] = []
                list.map(item => {
                   let term = `${item.compareType}&${item.value}`
                   let {parentInfo,childrenInfo } = this.parentChildInfo(item.sensorId)
                   this.ctlForm[key].push({
                      compareType: item.compareType,
                      value: item.value,
                      sensorId: item.sensorId,
                      term: term,
                      taskLinkageId: item.taskLinkageId,
                      showData: {
                        parentInfo: {
                          value: parentInfo.devId,
                          label: parentInfo.devName,
                        },
                        childrenInfo:{
                          value: childrenInfo.id,
                          label: childrenInfo.name,
                          unit: childrenInfo.enUnit
                        }
                      },
                      isEdit: false
                   })
                })
             }else{
               if(this.infos.hasOwnProperty(key)){
                 this.ctlForm[key] = this.infos[key]
               } 
             }
        })
        // 时间段处理
        let beginTime =  this.slotTimeHanlde(this.ctlForm.beginTime),
            endTime = this.slotTimeHanlde(this.ctlForm.endTime);
        this.$set(this.ctlForm,'timeSlot',[beginTime,endTime]) 
        if(beginTime !== '00:00' || endTime !== '23:59') this.butStutas = ''
      },
      slotTimeHanlde(data){
             if(data.includes(':')){
                let strArr = data.split(":")
                return `${strArr[0]}:${strArr[1]}`
             }
      },
      timeChange(){
          this.butStutas = ''
      }
    }
  }
</script>

<style lang="scss" scoped>
.linkageFrom{
  box-sizing: border-box;
  padding-right: 20px;
  .joint_from_box{
     display: flex;
     justify-content: space-between;
     color: #fff;
     .dev_info_text{
        max-width: 200px;
        display: inline-block;
        // line-height: normal;
     }
     
  }
  .ml-6{
    margin-left: 6px;
  }
 .demo-ruleForm{
      width: 100%;
      .but_box{
          text-align: right;
          margin-bottom: 0 !important;
      }
      .termTime_box{
          display: flex;
          align-items: center;
          .allDay_but{
              margin-left: 10px;
          }
      }
  
  }
  .switch_text{
   margin-left: 12px;
   color: #999;
 }
 .input_box ::v-deep .el-input__inner{
       text-align: center;
  }
  .unit_box{
       margin-right: 5px;
   }
   .el-radio-group ::v-deep .el-radio{
     margin-right: 20px !important;
   }
}
.c999{
    color: #999;
  }
</style>