<template>
  <div class="wh">
      <el-steps :active="stepsActive" finish-status="success" class="steps_customs_box" style="margin-bottom: 20px">
        <el-step title="配置执行器任务"></el-step>
        <el-step title="选择执行器"></el-step>
        <!-- <el-step title="新增任务"></el-step> -->
        <el-step title="完成新增"></el-step>
      </el-steps>
      
      <taskPlanFrom ref="planFrom" @change="radioFunChange" v-if="stepsActive == 1" :taskId="taskId" ></taskPlanFrom>
      <checkAssignmentFrom v-if="stepsActive > 1" :funTypeId="funTypeId" v-model="astInfoList" ref="astRef" :taskId="taskId" :astId="astActiveId"></checkAssignmentFrom>
      
      <div class="dialog-footer boxBg tr mt-20">
        <el-button size="small" @click="handleClose">取 消</el-button>
        <el-button size="small" type="primary" @click="toPrev" v-if="stepsActive > 1" plain>上一步</el-button>

        <el-button size="small" type="primary" @click="toNext">{{ nextButText }}</el-button>
      </div>
    
  </div>
  
</template>

<script>
import taskPlanFrom from './taskPlanFrom.vue'
import checkAssignmentFrom from './checkAssignmentFrom.vue'
import bus from '@/libs/eventBus.js'
  export default {
    props:{
      taskId: String,
    },
    components: {taskPlanFrom,checkAssignmentFrom},
    data(){
      return {
        stepsActive: 1,
        astInfoList:[],
        formData: null,
        funTypeId: ''
      }
    },
    computed:{
       nextButText(){
         return this.stepsActive > 2 ? '完成新增' : '下一步'
       },
       astActiveId(){
         return this.formData ?.astActiveId
       },
       ctlActiveTaskId() { return this.$store.getters.ctlActiveTaskId },
    },
    methods:{
      
      // checkAst(data){
        
      //    this.astInfoList = data
         
      // },
      radioFunChange(info){
          this.funTypeId = info.id
      },
      // toAdd(){
    
      //   if(!this.ctlActiveTaskId || this.ctlActiveTaskId == 'add') return this.$msg.info('请先添加任务')
      //   this.dialogVisible = true
        
      // },
      toPrev(){
        this.stepsActive--
      },
      handleClose(){
        this.dialog.close()
      
      },
      toNext(){
         switch(this.stepsActive){
           case 1:
            this.next1()
            break;
           case 2: 
            this.next2()
            break;
        
         }
      },
      next1(){
        this.$refs.planFrom.validData(data => {
          this.formData = data
          this.astInfoList = []
          this.stepsActive = 2
        })
        
      },
      next2(){
        if(!this.astInfoList || !this.astInfoList.length) return this.$msg.error('请先选择执行器')
        this.submitForm()
      },
      async submitForm(){
          this.mergeData()
          const res = await this.$api.AddIotTaskCmd(this.formData)
          if(res){
            this.$msg.success('新增成功')
            setTimeout(()=>{
              if(res) bus.$emit('updateData')
              this.handleClose()
            },500)
          }
      },
      mergeData(){
        this.formData.actuatorIds = this.astInfoList.map(item => item.id)
      }
 
    }
  }
</script>

