import config from '@/config'
import { getLocal, saveLocal} from '@/libs/util'
export default {
    methods:{
        // 新增设备队列 
        addLocaCtrPwd(devId,pwd){
            if(!pwd || !devId) return
            let data = new Object
            let nowTime = new Date().getTime()
            let datainfo = {
                times: nowTime,
                pwd
            }
            data[devId] = datainfo
            let pwdList = getLocal('pwdList')
            pwdList = pwdList ? JSON.parse(pwdList) : ''
            // 当 pwdList 有值时，更新devId值，否则创建保存pwdlist
            if(pwdList){
                pwdList[devId] = datainfo
                saveLocal('pwdList',pwdList)
            }else{
                saveLocal('pwdList',data)
            }   
        },
        // 设备密码是否验证通过
        isPwdPass(devId){
           let pwdList = getLocal('pwdList')
           pwdList = pwdList ? JSON.parse(pwdList) : ''
           
           if(!pwdList) return false
           
           if(pwdList.hasOwnProperty(devId)){
              // 有效期是否过时 ，过期删除该设备队列   
              let isPass= this.expirationTime(pwdList[devId].times) 
              
              if(!isPass){
                // 删除设备队列 ，保存队列
                delete pwdList[devId]
                saveLocal('pwdList',pwdList)
              } 
              return isPass
           } else return false
        },
        // 获取密码
        getPwdVal(devId){
     
            let pwdList = getLocal('pwdList')
            pwdList = pwdList ? JSON.parse(pwdList) : ''
            
            if(!pwdList) return false
           
            return pwdList.hasOwnProperty(devId) && pwdList[devId].pwd ?
                   pwdList[devId].pwd : false
        },
        // 有效期验证 默认 5分钟
        expirationTime(sTime){
           let eTime = config.ctlPwdTermTime
           let nowTime = new Date().getTime()
           
           return  (nowTime - eTime) < sTime ? true : false
        },
        
    }
}