<template>
   <div class="assignment_from_box">
    <div class="flex-fills">
      <p style="color: #fff;">执行器选择</p>
      <div class="form_box item_box borderBase boxScroll mt-10">
        <el-tree
          class="checkAssignmentTree boxBg"
          :data="list"
          ref="treeRef"
          show-checkbox
          node-key="id"
          @check-change="check"
          :default-expanded-keys="expandArr"
          :default-checked-keys="checkedKeysArr"
          :props="defaultProps">
        </el-tree>
      </div>
    </div>
    <div class="arrow_box borderBase baseColor">></div>
    <div class="flex-fills">
      <p style="color: #fff;">已选择执行器</p>
      <div class="drag_box item_box borderBase boxScroll mt-10">
        <draggable
          filter=".undraggable"
          class="card grid-container"
          v-model="checkInfoArr"
          item-key="id"
          animation="300"
          chosenClass="chosen"
        >
        <div class="show_row_box" v-for="(item,i) in checkInfoArr" :key="item.id">
           <div>
             <span class="index_box borderBase baseColor">{{ i + 1 }}</span>
             <span class="baseColor font-13">{{ item.name}}</span>
           </div>
           <span class="row_del_icon active-cp el-icon-close" @click="delChecked(item,i)"></span>
        </div>
       </draggable>
        <hnEmptyData size="small" text="暂未选中" v-if="!checkInfoArr || !checkInfoArr.length"></hnEmptyData>
      </div>
    </div>
   
              
   </div>
</template>

<script>
  import draggable from 'vuedraggable'
  export default {
    components: {draggable},
    props:{
      value:{
         type: Array,
         default:() => []
      },
      astId:{
         type: String
      },
      taskId: String,
      funTypeId: String 
    },
     data(){
       return{
          list: [],
          defaultProps: {
              children: 'children',
              label: 'name'
          },
          expandArr: [],
          checkInfoArr: [],
          checkedKeysArr: []
       }
     },
     watch:{
      'astId':{
        handler (val){
            if(val) this.reqIotControlActuatorTreeList(val)
         },
         immediate: true
      },
      'checkInfoArr':{
          handler(list){
            
            this.$emit('input',list)
            this.$emit('change',list)
            
          },
          deep:true
      },
      'value':{
          handler(val){
            if(val && val.length){
              this.checkedKeysArr = val.map(item => item.id)
              this.checkInfoArr = val
            }else {
            
              if(this.checkInfoArr.length){
                this.checkInfoArr = []
                this.checkedKeysArr = []
              }  
            }
          },
          immediate: true
      }
     },
     methods:{

       //  获取执行器列表
       async reqIotControlActuatorTreeList(executorTypeId){
           const res = await this.$api.IotControlActuatorTreeList({ 
            executorTypeId,
            // isDevNew: true,
            taskId:this.taskId,
            funTypeId: this.funTypeId
          })

           if(res && res.length){
            this.list = res || []
            this.expandArr = res.map(item => item.id)
           }
           
       },
       delChecked(data,i){
          this.$refs.treeRef.setChecked(data,false) 
          this.checkInfoArr.splice(i,1)
       },
       check(data,isCheck){
        if(data.children && data.children.length) return 
        if(isCheck){
          // 选中
          this.checkInfoArr.push(data)
        }else{
          // 未选中
          let index = -1
          this.checkInfoArr.some((item,i) => {
             if(item.id == data.id) return index = i
          })
          if(index > -1) this.checkInfoArr.splice(index,1)
        }
       }
     }
  }
</script>

<style lang="scss" scoped>
.assignment_from_box{
   display: flex;
   align-items: center;
   max-height: 240px;
   overflow-y: auto;
   .item_box{
      height: 200px;
      overflow-y: auto;
      border-radius: 8px;
      padding: 10px;
      box-sizing: border-box;
   }
   .flex-fills{
     flex: 1;
   }
   .drag_box{
      min-height: 200px;
      border: 1px solid var(--el-color-primary);
      border-radius: 10px;
   }
   .arrow_box{
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      border-radius: 50%;
      margin: 0 15px;
   }
   .form_box{
 

   }
   .show_row_box:not(:first-child){
     margin-top: 12px;
   }
   .show_row_box{
      display: flex;
      justify-content: space-between;
      font-size: 12px;

      & > div:first-child{
         display: flex;
         align-items: center;
         .index_box{
           width: 16px;
           height: 16px;
           border-radius: 10px;
           line-height: 16px;
           text-align: center;
           margin-right: 10px;

         }

      }
      .row_del_icon{
         color: #ccc;
         font-size: 14px;
      }
   }
}

</style>