import pwdFrom from '../pwdFrom.vue'
export default {
  methods:{
    // 控制开始函数
    ctlOnOff(item,orderInfo,ctlStatusName){

      // 1.当设备不在线，不执行控制
      if(this.isOnline !== 9) return this.$msg.info('设备离线中，无法操作')
      // 2单通道相同状态不能控制

      if(!item.isReversible && orderInfo.funValue == item.channelStatus) return
      if(item.isReversible){
        const ctlType = orderInfo.funValue == 1 ? 'open' : orderInfo.funValue == 2 ? 'colse' : ''
        if(ctlType){
          const isDisableCtl = this.isDisableBut(item,ctlType)
          if(isDisableCtl) return 
        } 
         
      }
      // if( item.isReversible &&((item.openPercent == 0 && orderInfo.funValue == 2) || (item.openPercent == 100 && orderInfo.funValue == 1)) ) return 

      // 3.设备在线执行控制 
      if(item && this.devStatus){ 
        const confirmText = `您正在控制设备${ctlStatusName}，确认继续？`
        this.$confirm(confirmText, "提示")
        .then(() => {
            // 4.初始化控制请求值
            let data = {
                id: item.devId,
                actuatorId: item.id,
                devCode: item.devCode,
                no: item.channelNo,
                reversalNo: item.reverseChannelNo,
                stopNo: item.closeChannelNo,
                openType: orderInfo.funValue,
                exeNo: item.actuatorCode,
                delay: item.delay,
                percent: orderInfo.openPercent
            }
            // 7.判断是否需要输入密码
            if(this.isNeedPwd()){
                // 8.保存一份控制请求值，用于密码验证组件回调
                this.showDialog(pwdFrom,{},{
                  width:'400px',
                  title: '控制密码',
                  dialog:{
                    isCenter: true,
                    confirmButtonText: '提交'
                  }
                },(pwd)=> {
                    data.ctrlPwd = pwd
                    this.createQueue({
                      reqData: data,
                      ctlName: ctlStatusName
                   })
                    // 2.开始控制
                    this.reqCtl(data.exeNo,data.actuatorId)
                })
            }else{
                // 9.获取本地设备密码信息，并添加进请求数据中
                let pwd = this.getPwdVal(this.devInfo.devId)
                if(pwd) data.ctrlPwd = pwd
                // 10.添加队列
                this.createQueue({
                   reqData: data,
                   ctlName: ctlStatusName
                })
                // 11.开始控制
                this.reqCtl(data.exeNo,data.actuatorId)
            }
        })
        
      }
    }, 
    // 创建控制队列，执行控制
    createQueue(data){
       let reqData = data ?.reqData
       // 1.获取当前执行器信息
       let actuatorInfo = this.getActuatorInfo(reqData.exeNo,reqData.actuatorId)
       
       // 2.初始化执行器执行队列
       let queueInfo = {
          reqData: null,  // 请求数据
          rowData: actuatorInfo,  // 当前执行器数据
          isAloneCtl: true, // 是否为单点控制
          oldOpenType: reqData.openType, // 执行动作备份
          oldOpenPercent: reqData.percent,
          no: reqData.exeNo, // 执行器编号
          isOutSide: false, // 是否是外部队列
          ctlName: '执行中...'
      } 
      Object.assign(queueInfo,data)
      // 2.加入队列
      this.addTaskQueue(queueInfo)
     
    },
    // 请求单个执行器控制
    async reqCtl(no,actuatorId){
   
      try{

        let queueItemData = this.getTaskQueueInfo(no,actuatorId)

        let {reqData,rowData } =  queueItemData         
        //  开始动画 
        rowData.isLoading= true
   
        const res = await this.reqIotDeviceCtrlChannel(reqData)
        if(this.devInfo.isSetPwd && res) this.addLocaCtrPwd(this.devInfo.devId,reqData.ctrlPwd)
        if(res ?.isOk){
          // 老设备处理函数
          if(this.isOldDev){
            // 控制成功
            setTimeout(()=>{ 
              let status = 1
              let percentage = 100
              
              if( rowData.isReversible ){
                 status = reqData.openType
                 percentage = reqData.openType == 1 ? 100 : 0
              
              }else{
            
                status = reqData.openType
                 percentage = reqData.openType == 1 ? 100 : 0 
              }
            
              this.updateActuatorIdStatus({
                 no: res.actuatorId,
                 status,
                 percentage,
              })
              this.delTaskQueueInfo(queueItemData.no,res.actuatorId)
            },1000)  
          }
        }else{
          if(this.isOldDev){              
              // 开启控制失败动画
              rowData.isErrorLoading = true
              // 延迟2秒关闭
              setTimeout(()=>{
                  // 关闭加载动画
                  rowData.isLoading = false
                  rowData.isErrorLoading = false
                
                  // 删除已经完成的执行器队列
                  this.delTaskQueueInfo(queueItemData.no,res.actuatorId)
              },2000)
          }else{
            this.updateActuatorStatus({
              no: no,
              status: rowData.channelStatus,
              percentage: rowData.openPercent
            })
            // 删除已经完成的执行器队列
            this.delTaskQueueInfo(no)
          }
          
        }
      }catch{
         console.log('错误')
      }
    },
    // 暂停控制
    pauseCtl(no){
 
      let queueInfo = this.getTaskQueueInfo(no)
      if(queueInfo.isOutSide){

      }else{
         // 1.设置请求控制参数为暂停  
         queueInfo.reqData.openType = 0
         // 2.启动暂停按钮的加载动画
         queueInfo.rowData.pauseLoading = true
         // 3.请求控制 
         this.reqCtl(no)
      }
     
    
    },
    // 完成控制
    finishCtl(no){
      let queueItemData = this.getTaskQueueInfo(no)
      this.updateActuatorStatus(queueItemData.executors)
      // 4.删除已经完成的控制器队列     
      this.delTaskQueueInfo(no)
    },
    // 继续控制
    nextCtl(no){
      let queueInfo = this.getTaskQueueInfo(no)
      // 1.还原控制值
      queueInfo.reqData.openType = queueInfo.oldOpenType
      queueInfo.reqData.percent = queueInfo.oldOpenPercent,
      // 2.重新开始控制
      this.reqCtl(no)
      // 3.还原暂停按钮显示
      queueInfo.rowData.isCtlPause = true  
      queueInfo.rowData.showPause = false 
    },
    // 新增队列
    addTaskQueue(info){
      let flag = true
      this.taskQueueInfo.some(item =>{
         if(item.no == info.no && !this.isOldDev) return flag = false
      })
      if(flag) this.taskQueueInfo.push(info)
    },
    // 遍历加载批量控制动画显示隐藏
    batchCtlLoadingAnime(loading = true){
        this.taskQueueInfo.map(item => {
            if(!item.isAloneCtl){
              if(loading) return item.rowData.isLoading = true
              if(item.rowData.isReversible){
                  // 关闭暂停按钮动画
                  item.rowData.pauseLoading = false
                  // 还原暂停按钮
                  item.rowData.isCtlPause = true
                  // 
                  item.rowData.showPause = false
              }
              // 关闭动画
              item.rowData.isLoading = false
              // 关闭控制失败动画
              item.rowData.isErrorLoading = false  
            } 
        })
    },
    // 更新批量控制数据列表
    updateBatchData(actuators = []){
      this.taskQueueInfo.map(item => {
        if(!item.isAloneCtl) {
          actuators.some(v => {
            if(v.actuatorId == item.rowData.id){
              return  item.rowData.channelStatus = v.openType
            }
          })
        }
        
        
      })
    },
    // 批量删除控制队列
    batchDelTaskQueue(actuators = []){
      for (let i=0; i< this.taskQueueInfo.length; i++){
        if(!this.taskQueueInfo[i].isAloneCtl) {
          actuators.some(v => {
            if(v.actuatorId == this.taskQueueInfo[i].rowData.id){
              this.taskQueueInfo.splice(i, 1)
              i--
              return
            }
          })
        }
        
    }

    },
    // 获取指定队列信息
    getTaskQueueInfo(no,actuatorId){
      let info = null
      this.taskQueueInfo.some(item => {
        if(this.isOldDev || !no){
          if( actuatorId && item.rowData.id == actuatorId){
              return info = item
          }
        }else{
          if(item.no == no) return info = item
        }  
      })
      return info
    },
    // 删除指定队列信息
    delTaskQueueInfo(no,actuatorId){
      this.taskQueueInfo.some((item,i) => {
        if(this.isOldDev){
          if( 
            actuatorId 
            && item.rowData.id == actuatorId){
              return this.taskQueueInfo.splice(i,1)
          }
        }else{
          if(item.no == no) return this.taskQueueInfo.splice(i,1)
        }
       
      })
    },
    // 更新指定执行器状态
    updateActuatorStatus(item){
      this.ctlDevList.map(ele => {
        if(ele.actuatorCode == item.no){
          if(ele.isReversible){
             // 关闭暂停按钮动画
            ele.pauseLoading = false
            // 还原暂停按钮
            ele.isCtlPause = true
            ele.showPause = false
          }
           // 关闭动画
           ele.isLoading = false
           // 关闭控制失败动画
           ele.isErrorLoading = false  
           //  更新状态
           ele.channelStatus = item.status
           //  更新百分比
           ele.openPercent = item.percentage
          
        }
      })
  
    },
    updateActuatorIdStatus(item){

      this.ctlDevList.map(ele => {

        if(ele.id == item.no){
         
          if(ele.isReversible){
             // 关闭暂停按钮动画
            ele.pauseLoading = false
            // 还原暂停按钮
            ele.isCtlPause = true
            ele.showPause = false
          }
           // 关闭动画
           ele.isLoading = false
           // 关闭控制失败动画
           ele.isErrorLoading = false  
           //  更新状态
           ele.channelStatus = item.status
           //  更新百分比
           ele.openPercent = item.percentage
          
        }
      })
  
    },
    // 获取指定执行器信息
    getActuatorInfo(no,actuatorId){
      let info = null
      this.ctlDevList.some(item => {
        
        if(this.isOldDev){
          if( 
            actuatorId 
            && item.id == actuatorId){
              return info = item
          }
        }else{
          if(item.actuatorCode == no) return info = item
        }
      })
      return info
    },
    // 判断是否是正反转执行器
    isReversible(no){
      let result = false
      this.ctlDevList.some(ele => {
        if(ele.actuatorCode == no){
           result = ele.isReversible
           return true
        }
      })
      return result
    },
    // 控制请求不通过，再次发起请求
    // againCtlReq(errorFun,no){
    //   this.againReqCount++
    //   if(this.againReqCount <= 3){
    //      this.reqCtl(no)
    //   }else{
    //     this.againReqCount = 0
    //     errorFun()
    //   } 
    // },
   
  }
}