<template>
  <div class="tool_but_box">
    <el-tooltip class="item" effect="dark" content="定时任务" placement="top-start">
      <div @click="toCtl('time')">
        <span class="iconfont icon-dingshi"></span>
      </div>
    </el-tooltip>
    <el-tooltip class="item" effect="dark" content="联控任务" placement="top-start">
      <div @click="toCtl('joint')">
       <span class="iconfont icon-liankong"></span>
     </div>
    </el-tooltip>
    <el-tooltip class="item" effect="dark" content="立即执行任务" placement="top-start">
      <div @click="toCtl('now')">
      <span class="iconfont icon-zhihang"></span>
     </div>
    </el-tooltip>
    <el-tooltip class="item" effect="dark" content="关闭设备" placement="top-start">
      <div @click="toCtl('close')">
      <span class="iconfont icon-guanbi2"></span>
     </div>
    </el-tooltip>
    
  </div>
</template>

<script>
  export default {
     data(){
       return{
          
       }
     },
     methods:{
      toCtl(code){
         this.$emit('onCtl',code)
      }
     }
  }
</script>

<style lang="scss" scoped>
.tool_but_box{
   position: absolute;
   right: -20px;
   top: 100px;
   color: #333;
   & > div{
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 20px 0 0 20px;
    background-image: linear-gradient(to bottom right, #00FFC5, #00DBF6);
    
    .iconfont{
      font-size: 28px;
    }
   }
   & > div:not(:first-child){
     margin-top: 10px;
   }
   & > div:hover{
    background-image: linear-gradient(to right,#00DBF6,#00FFC5, ); 
    cursor: pointer;
    color: #fff;
   }
}
</style>