<template>
  <div class="pageBg addDtos_box">
    <el-form :model="dtosForm" :rules="rules" ref="ctlForm" :hide-required-asterisk="true" size="small"  class="demo-ruleForm">
        <el-form-item  prop="sensorId">
          <el-cascader
              v-if="!devId"
              style="width: 100%"
              placeholder="请选择关联设备"
              v-model="dtosForm.sensorId"
              @change="sensorChange"
              :options="devSelectOptions"
              :props="{ emitPath: false }"
              ></el-cascader>
              <el-select 
              v-model="dtosForm.sensorId" 
              :options="devSelectOptions" 
              placeholder="请选择关联设备" 
              @change="selectChange"
              style="width: 100%"  v-else>
                <el-option
                  v-for="item in devSelectOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item  prop='term'>
              <termInput v-model="dtosForm.term" :unit='paramUnit'></termInput>
          </el-form-item>
             
      </el-form> 
      <div class="but_box">
          <el-button type="warning" plain @click="resetForm('ctlForm')" size="mini">取消</el-button>
          <el-button type="primary" plain @click="submitForm('ctlForm')" size="mini">{{ butText }}</el-button>
      </div> 
  </div>
</template>

<script> 
  import termInput from './termInput.vue'
  import { devCodeAndId,mapTo,getParentChildInfo } from '@/libs/util'
  export default {
     components: {termInput},
     props:{
      infos: Object,
      isEdit:{
        type: Boolean,
        default: false
      },
      devId: String 
     },
     data(){
       return {
        dtosForm:{
          compareType: '',
          value: '',
          sensorId: '',
          term: '',
          showData: null,
          isEdit: false
        },
        rules: {
          term: [
              { required: true, message: '请选择执行条件', trigger: 'blur' }, 
          ],
          sensorId: [
              { required: true, message: '请选择传感器参数', trigger: 'change' }, 
          ]
        },
        // devSelectOptions:[],
        paramUnit: '',
        parentChildInfo: null
       }
     },
     created(){
      if(this.isEdit){
         mapTo(this.infos,this.dtosForm)     
      }
      
      // this.reqIotDevSensorChooseList()
    },
    computed:{
       butText(){
         return this.isEdit ? '编辑' : '新增'
       },
       devSensorList(){ 
          return this.$store.state.devSensorList || []   
      },
       devSelectOptions(){
         
           let result = []
           let sensorId = this.infos ?.sensorId ||  ''
           this.devSensorList.some(item => {
                if(this.devId){
                  if(item.devId == this.devId){
                     result = item.sensors
                     return true
                  }
                }else{
                  let arrList = {
                      value: item.devId,
                      label: item.devName,
                      children: []
                  }
                  
                  if(item.sensors && item.sensors.length){
                      item.sensors.forEach(ele => {
                            if(sensorId &&  ele.id == sensorId){
                               
                                this.paramUnit = ele.enUnit
                            }
                            arrList.children.push({
                                value: ele.id,
                                label: ele.name,
                                unit: ele.enUnit
                            })
                      })
                  }else{
                      arrList.children= null
                  }
                  result.push(arrList)
                }
                 
            })
          
            return result
                  
       },
      
    },
    methods:{
      resetForm(){
        this.dtosForm= {
          compareType: '',
          value: '',
          sensorId: '',
          term: '',
          isEdit: false
        }
        this.$emit('close')
      },
      submitForm(formName){
        this.$refs[formName].validate(valid => {
           if(valid){
       
              let { devId, devCode } = devCodeAndId(this.dtosForm.term)
              this.dtosForm.compareType = devId
              this.dtosForm.value = devCode
              this.$emit('submit',this.dtosForm)
           }
        })
      },  
      sensorChange(data){
        let infos = getParentChildInfo(this.devSelectOptions,data)

        this.paramUnit = infos ?.childrenInfo.unit
        this.dtosForm.showData = infos
      },
      selectChange(data){

        let {parentInfo,childrenInfo } = getParentChildInfo(this.devSensorList,data,{childKey: 'sensors',value: 'id' })
        this.paramUnit = childrenInfo.enUnit
        this.dtosForm.showData = {
          parentInfo: {
            value: parentInfo.devId,
            label: parentInfo.devName,
          },
          childrenInfo:{
            value: childrenInfo.id,
            label: childrenInfo.name,
            unit: childrenInfo.enUnit
          }
        }
      }
     }
  }
</script>

<style lang="scss" scoped>
.addDtos_box{
   padding: 10px 10px 10px 10px;
   box-sizing: border-box;
}
.but_box{
  text-align: right;
}
</style>