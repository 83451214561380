<template>
  <div class="ctl_container_box boxBg br10">
      <div class="ctl_head_box mb-14">
        <div class="head_left"><slot name="h-left"></slot></div>
        <div class="head_center"><slot name="h-center"></slot></div>
        <div class="head_right"><slot name="h-right"></slot></div>
      </div>
      <div class="ctl_content ctl--bg-image br10">
         <slot></slot>
      </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
.ctl_container_box{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  box-sizing: border-box;
  margin-left: 20px;
  .ctl_head_box{
     min-height: 30px;
     flex-shrink: 0;
     display: flex;
     align-items: center;
     & > div{
       flex: 1;
     }
     .head_right{
       display: flex;
       justify-content: flex-end;
     }
     .head_center{
       justify-content: center;
       text-align: center;
     }
     .head_left{

     }
  }
  .ctl_content{
    flex: 1;
    min-height: 0;
    white-space: 0;
  }
}
</style>