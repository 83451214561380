import { 
  imgAddHost,
}from '@/libs/util'
export default {
  computed:{
    loadingText(){
      return item => {
           // 执行中...
          let queueInfo = this.getTaskQueueInfo(item.actuatorCode,item.id)
          let text = queueInfo ?.ctlName + '中...' || '执行中...'
          if(this.isOldDev) return text
          return item.isReversible ? `${text}当前开合度${item.openPercent}%` : text
      }
      
    },
    isOpenIcon(){
       return (item,key = 'openPercent') => {
        if(item.channelStatus > 2) return false
        if(this.isOldDev) return item.channelStatus == 1 || (item.channelStatus == 0 && item.isReversible)  ? true : false
        return item.isReversible ? 
               item[key] > 0 
               : item.channelStatus == 1      
       }
    },
   
  },
  methods:{
    isDisableBut(item,type){
      if(type == 'open'){
        if(this.isOldDev) return item.channelStatus < 3 && item.channelStatus == 1 ? true : false
        return item.isReversible ? item.openPercent == 100 ? true : false : item.channelStatus == 1 ? true : false
      }else if(type == 'colse'){
        if(this.isOldDev) return item.channelStatus < 3 && (item.channelStatus == 2 || (item.channelStatus == 0 && !item.isReversible)) ? true : false
        return item.isReversible ? item.openPercent == 0 ? true: false: item.channelStatus == 0 ? true: false
      }
      
    },
    isActiveClass(item,key = 'openPercent'){
 
      if(item.channelStatus > 2) return ''
      if(this.isOldDev) return item.channelStatus == 1 || (item.channelStatus == 0 && item.isReversible)  ? 'ctr_box_border' : ''
        return item.isReversible ? 
               item[key] > 0 ? 'ctr_box_border' : ''
                 : item.channelStatus == 1 ? 'ctr_box_border' : ''
               
    },
    actuatorStutasColor(item,key = 'openPercent'){
      // return item.channelStatus > 2 ? 
      // 'standbyBg' : item.openPercent > 0 ? 
      // 'active' : 'offLineBg' 
      if(item.channelStatus > 2) return 'standbyBg'
      if(this.isOldDev) return item.channelStatus == 1 || (item.channelStatus == 0 && item.isReversible ) ? 'active' : 'offLineBg' 
      if(item.isReversible){
        return item[key] > 0 ? 'active' :  'offLineBg' 
      }
      return item.channelStatus == 1 ? 
      'active' : item.channelStatus == 0 ?  
      'offLineBg' : 'standbyBg'
    }, 
    percentageColor(item){
      return item.channelStatus > 2 ? '#ffa800' : item.openPercent == 0 ? '#999' : '#00C395'
    },
    progressFormat(percentage,item){
      
        return  item.channelStatus > 2 ? `异常` : `${percentage}%` 
    },
    actuatorStatusTextColor(item,key = 'openPercent'){
        if(item.channelStatus > 2) return 'standbyColor'
        if(this.isOldDev) return item.channelStatus == 1 || (item.channelStatus == 0 && item.isReversible ) ? 'baseColor' : 'offLineColor' 
        if(item.isReversible){
          return item[key] > 0 ? 'baseColor' :  'offLineColor' 
        }
        return item.channelStatus == 1 ? 
        'baseColor' : item.channelStatus == 0 ?  
        'offLineColor' : 'standbyColor'
    },
    actuatorStatusText(item,key = 'openPercent'){
       
        if(item.channelStatus > 2) return '执行器异常'
        if(this.isOldDev) return item.channelStatus == 1 ? '已'+item.openChannelName || '已打开' : item.channelStatus == 2 || (item.channelStatus == 0 && !item.isReversible)  ? '已'+item.closeChannelName || '已关闭'  : '已暂停'
        if( item.isReversible ){
          return item[key] == 100 ? '已'+item.openChannelName || '已打开' : item[key] == 0 ? '已'+item.closeChannelName || '已关闭' :  `已${item.openChannelName}${item[key]}%` || `已打开${item[key]}%`
        }else{
          return item.channelStatus == 1 ? '已'+item.openChannelName || '已打开' : '已'+item.closeChannelName || '已关闭' 
        }

    },

    gifUrl(item){
      return  imgAddHost(item)
    },
  }
}